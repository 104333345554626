import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import MuiTableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import TableToolbar from './Toolbar'
import TableHead from './TableHead'
import Row from './Row'
import FiltersRow from './FiltersRow'
import TablePaginationActions from './Pagination'
import { classes as cl } from '../../utils/classes'

const useStyles = makeStyles((theme) => ({
  autoHeight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // '& > div, & .MuiTableContainer-root': {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   flexGrow: 1,
    //   overflow: 'hidden',
    // },
  },
  flexColGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  overFlowHidden: {
    overflow: 'hidden',
  },
  root: {
    width: '100%',
    // minWidth: '100%',
  },
  paper: {
    minWidth: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    '& td': {
      height: '100%',
      whiteSpace: 'nowrap',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  withSeparators: {
    '& .MuiTableCell-root': {
      position: 'relative',
      '&:after': {
        background: 'rgba(224, 224, 224, 1)',
        content: '""',
        display: 'block',
        height: '50%',
        position: 'absolute',
        right: -0.5,
        top: '50%',
        transform: 'translate3d(0, -50%, 0)',
        width: 1,
      },
    },
  },
}))

const defaultPatination = [] // [10, 20, 30]

const TableContainer = ({ autoHeight, classes, children, overrideClasses, disableContainer }) => {
  if (disableContainer) return children
  return (
    <div className={cl(classes.root, autoHeight && classes.autoHeight, overrideClasses.root)}>
      <Paper className={cl(classes.flexColGrow, classes.overFlowHidden, classes.paper, overrideClasses.paper)}>
        {children}
      </Paper>
    </div>
  )
}

const Table = (props) => {
  const {
    autoHeight = true,
    columns = [],
    classes: overrideClasses = {},
    data,
    disableContainer = false,
    ExpandedView = null,
    hasSearch = true,
    showFiltersButton = true,
    showToolbar = true,
    fillEmptyRows,
    filter,
    idField = '_id',
    limit = 20,
    onChange,
    paginationOptions = defaultPatination,
    selectable = true,
    selectedActions,
    selectionDisabled,
    size = 'medium',
    skip,
    sort,
    title = '',
    toolbarActions,
    total,
    withSeparators = true,
    withFilters = true,
  } = props

  const [[orderBy, order]] = Object.entries(sort)
  const classes = useStyles()
  const [selected, setSelected] = React.useState([])
  const [showFilters, setShowFilters] = React.useState(withFilters)
  const currentPage = skip ? (skip / limit) | 0 : 0

  const handleRequestSort = (event, property) => {
    const sort = {}
    if (property !== idField && order === '-1') {
      // When user clicks third time on the same column header, remove the sorting (reset to default)
      Object.assign(sort, { [idField]: '1' })
    } else {
      Object.assign(sort, { [property]: orderBy === property && order === '1' ? '-1' : '1' })
    }
    onChange({ sort })
  }

  const handleSelectAllClick = (event) => {
    if (event?.target?.checked) {
      const notDisabledRecords = selectionDisabled ? data.filter((record) => !selectionDisabled(record)) : data
      setSelected(notDisabledRecords.map((n) => n[idField]))
      return
    }
    setSelected([])
  }

  const onSelect = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    onChange({ skip: newPage * limit })
  }

  const handleChangeRowsPerPage = (event) => {
    // When records per page change, reset the current page to beginning (skip=0)
    onChange({ limit: parseInt(event.target.value, 10), skip: 0 })
  }

  const handleFilterChange = (filter) => {
    setShowFilters(filter)
    onChange({ filter: { ...filter } })
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const emptyRows = fillEmptyRows && limit - data.length
  // Pass filter toggle button state, and state setter only when withFilters is true
  const toolbarProps = { ...(showFiltersButton ? { showFiltersButton: !!showFiltersButton, setShowFilters } : {}) }

  return (
    <TableContainer
      autoHeight={autoHeight}
      classes={classes}
      overrideClasses={overrideClasses}
      disableContainer={disableContainer}
    >
      {showToolbar && (
        <TableToolbar
          actions={toolbarActions}
          numSelected={selected.length}
          selected={selected}
          hasSearch={hasSearch}
          selectedActions={selectedActions}
          deSelectAll={handleSelectAllClick}
          title={title}
          onChange={onChange}
          {...toolbarProps}
        />
      )}
      <MuiTableContainer className={cl(classes.flexColGrow, classes.overFlowHidden, overrideClasses.tableContainer)}>
        <MuiTable className={classes.table} aria-labelledby="tableTitle" size={size} aria-label={title}>
          <TableHead
            classes={classes}
            selectable={selectable}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={selectionDisabled ? data.filter((r) => !selectionDisabled(r)).length : data.length}
            columns={columns}
            withSeparators={withSeparators}
            hasDetails={!!ExpandedView}
          />
          <TableBody>
            {showFilters && (
              <FiltersRow
                filter={filter}
                hasDetails={!!ExpandedView}
                columns={columns}
                selectable={selectable}
                // showFilters={showFilters}
                setShowFilters={handleFilterChange}
              />
            )}
            {data.map((row, index) => {
              const selectProps = selectable
                ? { onSelect: () => onSelect(row[idField]), isItemSelected: isSelected(row[idField]) }
                : {}
              return (
                <Row
                  ExpandedView={ExpandedView}
                  classes={classes}
                  record={row}
                  key={index}
                  columns={columns}
                  labelId={`enhanced-table-checkbox-${index}`}
                  idField={idField}
                  selectionDisabled={selectionDisabled}
                  {...selectProps}
                />
              )
            })}
            {fillEmptyRows && emptyRows > 0 && (
              <TableRow style={{ height: (size === 'medium' ? 53 : 33) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </MuiTableContainer>
      <TablePagination
        rowsPerPageOptions={paginationOptions}
        labelRowsPerPage="Rows Per Page:"
        component="div"
        count={total}
        rowsPerPage={limit}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  )
}

const shouldRender = (prev, next) => {
  return prev.fetchedAt === next.fetchedAt
}

const MemoizedTable = React.memo(Table, shouldRender)

export default Table
export { MemoizedTable as Table }
