import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

import DialogTitle from './DialogTitle'
import OkButton from './Buttons/Ok'
import { isEmpty } from '../utils/object'
import { capitalize } from '../utils/string'

const useStyles = makeStyles((theme) => ({
  switch: {
    margin: `${theme.spacing()}px 0 0 auto`,
  },
}))

const initialState = { name: '', email: '', isAdmin: false, password: '', password2: '' }
const noErrors = { name: null, email: null, password: null }
const userProperties = Object.keys(initialState)
const ignoreInEdit = ['password', 'password2']

const AddUserDialog = ({ open, onClose, user: userProp }) => {
  const isEdit = userProp !== true
  const user = isEdit
    ? {
        ...userProp,
        // Add ignored fields with empty values to initial state
        ...ignoreInEdit.reduce((acc, k) => {
          acc[k] = ''
          return acc
        }, {}),
      }
    : { ...initialState }
  const classes = useStyles()
  const [errors, setErrors] = React.useState({ ...noErrors })
  const [userState, setUser] = React.useState(user)
  const requiredFields =
    // Remove passwords from required fields only when in edit mode and both fields are empty
    isEdit && !userState.password && !userState.password2
      ? userProperties.filter((k) => !ignoreInEdit.includes(k))
      : userProperties
  const title = isEdit ? 'Edit User' : 'Add New User'

  const handleChange = (event) => {
    const { name: field, value } = event.target
    if (errors[field]) setErrors({ ...errors, [field]: null })
    setUser({ ...userState, [field]: value })
  }

  const checkValues = () => {
    const tmpErrors = {}
    requiredFields.forEach((field) => {
      // Comparing with empty string will validate true/false value as well (isAdmin)
      if (userState[field] === '') {
        tmpErrors[field] = `${capitalize(field)} is required field!`
      }
    })
    // Extract passwords from the user state and check / merge them depending on usage (and remove material-table meta-data)
    const { password, password2, tableData, ...userObject } = userState
    // If creating a new user, or user provided both password fields - merge passwords back to user data
    if (!isEdit || (password && password2)) {
      Object.assign(userObject, { password })
    }

    // Check password validity
    if (!tmpErrors.password && !tmpErrors.password2) {
      if (userState.password !== userState.password2) {
        tmpErrors.password = 'Passwords must be identical'
        tmpErrors.password2 = 'Passwords must be identical'
      }
    }
    if (isEmpty(tmpErrors)) {
      onClose(userObject)
    } else {
      setErrors(tmpErrors)
    }
  }

  React.useEffect(() => {
    if (open) {
      setUser({ ...user })
      setErrors({ ...errors })
    }
  }, [open])

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title" onClose={() => onClose(false)}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <Box flexGrow={1} padding="8px">
          <Grid container spacing={3}>
            <Grid item md={12}>
              <TextField
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
                label="Full name"
                name="name"
                onChange={handleChange}
                required
                value={userState.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={userState.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={!!errors.password}
                helperText={errors.password}
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={userState.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={!!errors.password2}
                helperText={errors.password2}
                label="Confirm password"
                name="password2"
                onChange={handleChange}
                type="password"
                value={userState.password2}
                variant="outlined"
              />
            </Grid>
            <FormGroup className={classes.switch}>
              <FormControlLabel
                control={
                  <Switch
                    name="isAdmin"
                    checked={userState.isAdmin}
                    style={{ float: 'right' }}
                    color="primary"
                    onChange={({ target: { name, checked } }) => handleChange({ target: { name, value: checked } })}
                  />
                }
                label="User is admin"
                labelPlacement="end"
              />
            </FormGroup>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <OkButton variant="outlined" onClick={() => checkValues()}>
          Save
        </OkButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddUserDialog
