import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddBox'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ShowGraph from '@material-ui/icons/Timeline'
import Tooltip from '@material-ui/core/Tooltip'

import { formatDate } from '../utils/formatDate'
import { getEmail, isAdmin } from '../store/selectors/auth.selectors'
import { getPortfolio } from '../store/selectors/portfolios.selectors'
import { portfolioColumns } from '../components/ColumnAddons'
import { services } from '../store/feathers'
import AddEditAnnotation from '../components/AddEditAnnotation'
import AddEditPortfolio from '../components/AddEditPortfolio'
import MoveActions from '../components/MoveActions'
import Page from '../components/Page'
import StaticDataTable, { getComparator } from '../components/Table/StaticDataTable'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import SaveIcon from '@material-ui/icons/Save'
import EditCommentIcon from '../assets/svgIcons/comment-edit.svg'
import AddCommentIcon from '../assets/svgIcons/comment-plus.svg'

const useStyles = makeStyles((theme) => ({
  adornmentWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  adornmentCol: {
    marginLeft: theme.spacing(2),
    position: 'relative',
  },
  adornmentLabel: {
    position: 'absolute',
    color: theme.palette.action.active,
    fontSize: '.7rem',
    top: 0,
    left: 0,
  },
  adornmentTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1rem',
    fontSize: '1rem',
    '& span': {
      fontSize: '1rem',
      marginLeft: '1rem',
    },
    '& svg': {
      fontSize: '1rem',
    },
  },
}))
const useActionStyles = makeStyles((theme) => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

const clonePortfolio = ({ masks, testRecords, tests, updatedAt, ...portfolio }) => portfolio

const arrowsCol = {
  label: '',
  field: '',
  render: (record) => <MoveActions record={record} />,
}

const indexCol = {
  field: 'index',
  hasFilter: true,
  isSortable: true,
  label: 'Index',
  type: 'number',
  width: '1%',
}

const SelectedActions = ({ selected, deSelectAll }) => {
  const classes = useActionStyles()
  const dispatch = useDispatch()
  const portfolio = useSelector(getPortfolio)

  const deletePortfolioTest = (event, records) => {
    dispatch(
      services.portfolios.patch(portfolio._id, {
        $pull: {
          tests: portfolio.testRecords.filter(({ _id }) => selected.includes(_id)).map(({ UUID }) => UUID),
        },
      }),
    )
    deSelectAll()
  }

  return (
    <Tooltip title="Delete">
      <Button
        className={classes.noWrap}
        variant="outlined"
        color="inherit"
        onClick={deletePortfolioTest}
        startIcon={<DeleteIcon />}
      >
        Remove tests
      </Button>
    </Tooltip>
  )
}

const Portfolio = (props) => {
  const {
    match: {
      params: { portfolioId },
    },
  } = props
  const classes = useStyles()
  const columns = portfolioColumns(indexCol, arrowsCol)
  const dispatch = useDispatch()
  const currentPortfolio = useSelector(getPortfolio)
  const hasAdminRights = useSelector(isAdmin)
  const email = useSelector(getEmail)

  const history = useHistory()
  const [editPortfolio, setEditPortfolio] = React.useState(false)
  const [editAnnotation, setEditAnnotation] = React.useState(false)
  const [sort, setSort] = React.useState({})

  // Inject annotation callback that is run from this closure and therefore inherits local variables on each render
  columns.find(({ field }) => field === 'annotation-action').render = (record) => {
    const Icon = record?.annotation ? EditCommentIcon : AddCommentIcon
    return (
      <IconButton edge="end" aria-label="delete" onClick={() => setEditAnnotation(record)}>
        <SvgIcon component={Icon} viewBox="0 0 24 24" />
      </IconButton>
    )
  }

  const handleEditPortfolioDialog = (data) => {
    if (data) {
      dispatch(services.portfolios.patch(portfolioId, data)).then(() => {
        setEditPortfolio(false)
      })
    } else {
      setEditPortfolio(false)
    }
  }

  const updateAnnotation = (content) => {
    dispatch(services.tests.patch(editAnnotation.UUID, { annotation: content })).then(() => {
      dispatch(services.portfolios.get(portfolioId)).then(() => {
        setEditAnnotation(false)
      })
    })
  }

  const saveOrder = () => {
    if (Object.keys(sort).length > 0) {
      const [[orderBy, order]] = Object.entries(sort)
      const tests = Array.from(currentPortfolio.testRecords)
        .sort(getComparator(columns, currentPortfolio.testRecords, order, orderBy))
        .map(({ UUID }) => currentPortfolio.tests.find((test) => test.UUID === UUID))
        .filter(Boolean)
      dispatch(services.portfolios.patch(portfolioId, { tests })).then(() => {
        dispatch(services.portfolios.get(portfolioId))
      })
    }
  }

  const updateSort = (sort) => {
    setSort(sort)
  }

  return (
    <Page
      loadingSelector={(state) => state.portfolios.get === null}
      title={currentPortfolio?.name || ''}
      backTo={{ url: '/portfolios', title: 'Back to Portfolios' }}
      actions={[
        {
          title: 'Curve Metrics',
          icon: <ShowGraph />,
          onClick: () => history.push(`/portfolios/${portfolioId}/show-curve`),
        },
        ...(hasAdminRights || email === currentPortfolio?.creator || currentPortfolio?.isPublic === 'write'
          ? [
              {
                title: 'Add Tests',
                icon: <AddIcon />,
                onClick: () => history.push(`/portfolios/${portfolioId}/add-tests`),
              },
              {
                title: 'Update Portfolio Details',
                icon: <EditIcon />,
                onClick: () => {
                  const cloned = clonePortfolio(currentPortfolio)
                  setEditPortfolio(cloned)
                },
              },
              {
                title: 'Save current order',
                icon: <SaveIcon />,
                onClick: saveOrder,
              },
            ]
          : []),
      ]}
      titleAdornment={
        <div className={classes.adornmentWrapper}>
          <div className={classes.adornmentCol}>
            <div className={classes.adornmentLabel}>Creator</div>
            <div className={classes.adornmentTitle}>{currentPortfolio?.creator}</div>
          </div>
          <div className={classes.adornmentCol}>
            <div className={classes.adornmentLabel}>Created</div>
            <div className={classes.adornmentTitle}>{formatDate(currentPortfolio?.createdAt)}</div>
          </div>
          <div className={classes.adornmentCol}>
            <div className={classes.adornmentLabel}>Public</div>
            <div className={classes.adornmentTitle}>
              {currentPortfolio?.isPublic ? <CheckIcon /> : <CloseIcon />}
              {currentPortfolio?.isPublic && <span>{currentPortfolio?.isPublic}</span>}
            </div>
          </div>
        </div>
      }
    >
      <StaticDataTable
        columns={columns}
        defaultSort={{ index: 1 }}
        selectable={true}
        selector={(state) => state.get?.testRecords || []}
        recordId={portfolioId}
        service="portfolios"
        method="get"
        size="small"
        hasSearch={false}
        showFiltersButton={false}
        selectedActions={SelectedActions}
        withFilters={true}
        onSortChange={updateSort}
      />
      <AddEditAnnotation
        open={!!editAnnotation}
        onSave={updateAnnotation}
        onClose={() => setEditAnnotation(null)}
        annotation={editAnnotation?.annotation}
      />
      <AddEditPortfolio
        isEdit={true}
        open={!!editPortfolio}
        portfolio={editPortfolio}
        onClose={handleEditPortfolioDialog}
      />
    </Page>
  )
}

export default Portfolio
