import React from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'

// Local imports below
import * as themes from './utils/styles/theme'
import Routes from './Routes'

import { initialize } from './store/init/init.actions'
import { selectTheme } from './store/theme/theme.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalStyles } from './utils/styles/globalStyles'

function App() {
  useGlobalStyles()
  const dispatch = useDispatch()
  const appTheme = useSelector(selectTheme)

  React.useEffect(() => {
    dispatch(initialize())
  }, [])

  return (
    <ThemeProvider theme={themes[`${appTheme}Theme`]}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  )
}

export default App
