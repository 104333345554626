import * as selectors from './theme.selectors'
import slice from './theme.slice'

export const {
  name,
  actions: { switchTheme, toggleTheme },
  reducer,
} = slice

// we prefix all selectors with the the "select" prefix
export const { selectTheme } = selectors
