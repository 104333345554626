import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const ConditionalRoute = ({ component: Component, condition, redirect = '/login', force = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (condition) {
          return <Component {...rest} {...props} />
        } else {
          const pathname = !rest.location?.state?.from || force ? redirect : rest.location?.state?.from
          return (
            <Redirect
              to={{
                pathname,
                ...(!rest.location?.state?.from ? { state: { from: props.location } } : {}),
              }}
            />
          )
        }
      }}
    />
  )
}

export default ConditionalRoute
