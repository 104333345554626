import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { objectHas } from '../../utils/object'

const orderMap = {
  1: 'asc',
  '-1': 'desc',
}

function EnhancedTableHead(props) {
  const {
    classes,
    columns,
    hasDetails,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    selectable,
    withSeparators,
  } = props
  const cellsRef = React.useRef({})

  const ref = React.useCallback((node) => {
    if (node) {
      cellsRef.current[node.dataset.key] = `${node.offsetWidth}px`
    }
  }, [])

  // dynamic thead props, add className only when withSeparators is true
  const theadProps = {
    ...(withSeparators ? { className: classes.withSeparators } : {}),
  }
  return (
    <TableHead {...theadProps}>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {hasDetails && <TableCell width="1%" />}
        {columns.map((cell, index) => (
          <TableCell
            ref={ref}
            className={classes.nowrap}
            key={cell.field || `index-${index}`}
            align={cell.numeric ? 'right' : 'left'}
            data-key={cell.field}
            padding={cell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === cell.field ? orderMap[order] : false}
            width={objectHas(cell, 'width') ? cell.width : cellsRef.current[cell.field] || 'auto'}
          >
            {cell.isSortable ? (
              <TableSortLabel
                active={orderBy === cell.field}
                direction={orderBy === cell.field ? orderMap[order] : 'asc'}
                onClick={(event) => onRequestSort(event, cell.field)}
              >
                {cell.label}
                {orderBy === cell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === '-1' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// }

export default EnhancedTableHead
