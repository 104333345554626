import { isNumber } from './number'

const initialChartOptions = { yMin: '', yMax: '', autoScaleY: true, xMin: '', xMax: '', autoScaleX: true }
export const emptyPortfolioData = {
  curveLegendVisible: true,
  legendVisible: true,
  masks: [],
  tests: [],
  testRecords: [],
  quality: 0.5,
  intensity: 20,
  performance: 1.4,
  notSubtracted: false,
  scale: { ...initialChartOptions },
}

// Selection grid related definitions
export const LABELS = (num = 40) => [...Array(num > 0 ? num : 0).keys()].map((k) => k + 1)
export const CHAMBERS = [...Array(4).keys()].map((k) => k + 1)
export const CHANNELS = [...Array(16).keys()].map((k) => k + 1)
export const DYES = ['FAM', 'VIC', 'CFR', 'QSR']

// Color dialog definitions
export const colorOptions = ['Default', 'Channel', 'Chamber', 'instrumentId', 'UUID', 'sampleInfo1Value']
export const colorLabels = {
  sampleInfo1Value: 'Sample Info1',
  instrumentId: 'Instrument',
  UUID: 'Run ID',
}
export const defaultChannelColors = [
  ['FAM', { r: 54, g: 162, b: 235 }],
  ['VIC', { r: 75, g: 192, b: 192 }],
  ['CFR', { r: 255, g: 205, b: 86 }],
  ['QSR', { r: 255, g: 99, b: 132 }],
]
export const defaultChamberColors = [
  ['Chamber 1', { r: 54, g: 162, b: 235 }],
  ['Chamber 2', { r: 75, g: 192, b: 192 }],
  ['Chamber 3', { r: 255, g: 205, b: 86 }],
  ['Chamber 4', { r: 255, g: 99, b: 132 }],
]
export const defaultColor = { r: 218, g: 218, b: 218 }

// Function helpers
export const getDye = (channel) => {
  const residue = channel % 4
  return DYES[residue ? residue - 1 : 3]
}

export const getConfig = (scale, xTicks, yProp, tests, testRecords, colorConfig, curveLegendVisible, portfolioName) => {
  return {
    data: {
      labels: [...LABELS(scale.autoScaleX ? xTicks : scale.xMax || xTicks)],
      datasets: tests.reduce((acc, { UUID, channels, isMasked }, index) => {
        const test = testRecords.find((t) => t.UUID === UUID)
        // Channels starts from 1, to get the right value increase the index by 1
        const filtered = test.curves
          .filter((c, i) => channels.includes(i + 1))
          .map(({ data: originalData, ...rest }) => {
            const data = originalData.map(({ x, ...d }) => ({ x, y: d[yProp] }))
            return {
              data,
              ...rest,
            }
          })
        acc = acc.concat(filtered)
        return acc
      }, []),
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      title: {
        display: true,
        fontSize: 24,
        text: portfolioName || '',
      },
      legend: {
        display: curveLegendVisible,
        position: 'bottom',
        labels: {
          generateLabels: function (chart) {
            return (
              !colorConfig?.type || colorConfig.type === 'Default'
                ? defaultChannelColors
                : colorConfig[colorConfig.type]
            ).map(([label, rgb]) => {
              const color = `rgb(${rgb.r},${rgb.g},${rgb.b}`
              return {
                text: label,
                fillStyle: color,
                lineCap: 'butt',
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: 'miter',
                lineWidth: 3,
                strokeStyle: color,
              }
            })
          },
        },

        onClick(e, legendItem, legend) {
          // do nothing
        },
      },
      tooltips: {
        // Change to 'index' for vertical listing of all points on that line
        mode: 'point',
        callbacks: {
          label: function (tooltipItem, data) {
            const record = data.datasets[tooltipItem.datasetIndex]
            const index = record.label.split('.').shift()
            return `${index}.${record.sampleInfo1Value}-${record.chamber}/${
              record.channel
            }: ${tooltipItem.yLabel.toFixed(1)}`
          },
          title: function () {
            return ''
          },
        },
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Cycles',
            },
            ticks: {
              min: !isNumber(scale.xMin) || scale.autoScaleX ? undefined : Number(scale.xMin),
              max: !isNumber(scale.xMax) || scale.autoScaleX ? undefined : Number(scale.xMax),
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'RFU',
            },
            ticks: {
              min: !isNumber(scale.yMin) || scale.autoScaleY ? undefined : Number(scale.yMin),
              max: !isNumber(scale.yMax) || scale.autoScaleY ? undefined : Number(scale.yMax),
            },
          },
        ],
      },
    },
  }
}

export const extractData = (currentPortfolio, yProp) => {
  let AutoQuant = []
  let xTicks = 0
  if (currentPortfolio) {
    currentPortfolio.testRecords.forEach(({ UUID, autoQuant, curves }) => {
      const test = currentPortfolio.tests.find((t) => t.UUID === UUID)
      AutoQuant = AutoQuant.concat(autoQuant.filter((a, indx) => test.channels.includes(indx + 1)))
      xTicks = Math.max(xTicks, ...curves.map((c) => c.data.length))
    })
  }
  return [xTicks, AutoQuant]
}
