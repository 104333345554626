import { Component } from 'react'

class OnBeforeUnload extends Component {
  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault()
    if (this.props.message) {
      e.returnValue = this.props.message
      return this.props.message
    }
    return null
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  render() {
    return null
  }
}

export default OnBeforeUnload
