import React from 'react'
import { SketchPicker } from 'react-color'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'fixed',
    zIndex: 99999,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}))

const defaultColor = {
  r: '241',
  g: '112',
  b: '19',
}

const presetColors = [
  'rgb(230, 57, 70)',
  'rgb(255, 205, 86)',
  'rgb(178, 223, 48)',
  'rgb(86, 196, 255)',
  'rgb(92, 85, 224)',
  'rgb(213, 182, 230)',
  'rgb(243, 47, 231)',
  'rgb(168, 218, 220)',
  'rgb(252, 137, 67)',
  'rgb(89, 119, 27)',
  'rgb(10, 10, 10)',
  'rgb(174, 175, 88)',
  'rgb(18, 245, 55)',
  'rgb(189, 152, 228)',
  'rgb(187, 182, 166)',
  'rgb(235, 153, 231)',
]

const ColorPicker = ({ color = defaultColor, onChange = () => undefined }) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    displayColorPicker: false,
    anchorEl: null,
    color,
  })

  React.useEffect(() => {
    setState({ ...state, color })
  }, [color])

  const handleClick = (evt) => {
    setState({ ...state, anchorEl: evt.currentTarget })
  }

  const handleClose = () => {
    setState({ ...state, anchorEl: null })
  }

  const handleChange = (color) => {
    setState({ ...state, color: color.rgb })
    onChange(color.rgb)
  }

  const style = {
    background: `rgb(${state.color.r}, ${state.color.g}, ${state.color.b})`,
  }

  return (
    <div className={classes.root}>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} style={style} />
      </div>
      {state.anchorEl && <div className={classes.cover} onClick={handleClose} />}
      <Popper
        open={!!state.anchorEl}
        className={classes.popover}
        anchorEl={state.anchorEl}
        color={state.color}
        onClose={handleClose}
        transition
      >
        <SketchPicker color={state.color} presetColors={presetColors} onChange={handleChange} disableAlpha={true} />
      </Popper>
    </div>
  )
}

export default ColorPicker
