import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'

import { isAdmin } from '../store/selectors/auth.selectors'
import { portfoliosColumns } from '../components/ColumnAddons'
import { services, actions } from '../store/feathers'
import AddEditPortfolio from '../components/AddEditPortfolio'
import DataTable from '../components/Table/DataTable'
import Page from '../components/Page'
import { getPortfoliosQuery, getShowAll } from '../store/selectors/portfolios.selectors'

const Portfolios = () => {
  const dispatch = useDispatch()
  const hasAdminRights = useSelector(isAdmin)
  const showAll = useSelector(getShowAll)
  const portfolioQuery = useSelector(getPortfoliosQuery)

  // Add new portfolio dialog
  const [addPortfolio, setAddPortfolio] = React.useState(false)

  const handleNewPortfolioDialog = (data) => {
    if (data) {
      dispatch(services.portfolios.create(data)).then(() => {
        setAddPortfolio(false)
        dispatch(services.portfolios.find(portfolioQuery))
      })
    } else {
      setAddPortfolio(false)
    }
  }

  return (
    <Page
      key="portfolios"
      title="Available Portfolios"
      loadingSelector={(state) => state.portfolios.find === null}
      titleAdornment={
        hasAdminRights && (
          <Box flexGrow="1" textAlign="right" mr={2}>
            <Checkbox checked={showAll} onChange={() => dispatch(actions.portfolios.showAll(!showAll))} />
            Show All Portfolios
          </Box>
        )
      }
      actions={[
        {
          title: 'Create New Portfolio',
          icon: <AddIcon />,
          onClick: () => setAddPortfolio(true),
        },
      ]}
    >
      <DataTable
        columns={portfoliosColumns}
        selectable={false}
        service="portfolios"
        size="small"
        withFilters={true}
        hasSearch={false}
        showFiltersButton={false}
      />
      <AddEditPortfolio open={addPortfolio} onClose={handleNewPortfolioDialog} />
    </Page>
  )
}

export default Portfolios
