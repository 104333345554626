import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { isSaving } from '../store/selectors/portfolios.selectors'
import { overlayVisible } from '../store/overlay/overlay.selectors'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    color: '#fff',
    opacity: 0.5,
    zIndex: 99999,
  },
}))

const LoadingOverlay = () => {
  const classes = useStyles()
  const open = useSelector(isSaving)
  const overlayForced = useSelector(overlayVisible)

  return (
    <Backdrop className={classes.backDrop} open={open || overlayForced}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingOverlay
