import * as selectors from './modal.selectors'
import slice from './modal.slice'

export const {
  name,
  actions: { showModal, hideModal },
  reducer,
} = slice

export const { isOpen, getParams } = selectors
