import React from 'react'
import { useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core/styles'

import DialogTitle from './DialogTitle'
import OkButton from './Buttons/Ok'
import { getEmail, isAdmin } from '../store/selectors/auth.selectors'

const useStyles = makeStyles((theme) => ({
  switch: {
    margin: `${theme.spacing()}px 0 0 auto`,
  },
  radios: {
    justifyContent: 'flex-end',
  },
  textarea: {
    minHeight: theme.spacing(2) * 10,
  },
}))

const AddEditPortfolio = ({
  open,
  isEdit = false,
  portfolio = { name: '', description: '', isPublic: false },
  onClose,
}) => {
  const classes = useStyles()
  const [error, setError] = React.useState({ name: null, description: null })
  const [form, setForm] = React.useState(portfolio)
  const hasAdminRights = useSelector(isAdmin)
  const email = useSelector(getEmail)

  const title = isEdit ? 'Update Portfolio Details' : 'Add New Portfolio'

  const handleNameChange = (event) => {
    if (error.name) setError({ ...error, name: null })
    setForm({ ...form, name: event.target.value })
  }
  const handleDescriptionChange = (event) => {
    if (error.description) setError({ ...error, description: null })
    setForm({ ...form, description: event.target.value })
  }
  const handleIsPublicChange = (event) => {
    const {
      target: { value },
    } = event
    setForm({ ...form, isPublic: value === 'false' ? false : value })
  }
  const checkValues = () => {
    if (!form.name) {
      setError({ ...error, name: 'Name is a required field!' })
    } else {
      onClose(form)
    }
  }
  const privacyChangeDisabled = isEdit && !(hasAdminRights || email === portfolio.creator)

  React.useEffect(() => {
    if (open) {
      setForm(portfolio)
      setError({ name: null, description: null })
    }
  }, [open])

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title" onClose={() => onClose(false)}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          id="name"
          label="Name"
          type="text"
          fullWidth
          error={!!error.name}
          defaultValue={form.name}
          onChange={handleNameChange}
          helperText={error.name || ''}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          variant="outlined"
          type="text"
          error={!!error.description}
          multiline
          fullWidth
          rowsMax={4}
          defaultValue={form.description}
          onChange={handleDescriptionChange}
          InputProps={{
            classes: { input: classes.textarea },
          }}
        />
        <RadioGroup
          className={classes.radios}
          row
          aria-label="public"
          name="public"
          value={form.isPublic}
          onChange={handleIsPublicChange}
        >
          <FormControlLabel value="read" control={<Radio disabled={privacyChangeDisabled} />} label="Public read" />
          <FormControlLabel value="write" control={<Radio disabled={privacyChangeDisabled} />} label="Public write" />
          <FormControlLabel value={false} control={<Radio disabled={privacyChangeDisabled} />} label="Private" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <OkButton variant="outlined" onClick={() => checkValues()}>
          Save
        </OkButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditPortfolio
