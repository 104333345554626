import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import DialogTitle from './DialogTitle'
import { hideModal, getParams, isOpen } from '../../store/modal'

const Modal = () => {
  const dispatch = useDispatch()
  const open = useSelector(isOpen)
  const params = useSelector(getParams)
  const [
    { title, content, buttons, onConfirm, onCancel, cancel = true, confirmLabel = 'ok', basic = false },
    setParams,
  ] = React.useState(params)

  React.useEffect(() => {
    // Reset local state only when dialog opens again
    if (open) setParams(params)
  }, [open])

  const handleClose = (response) => {
    if (response === 'ok' && onConfirm) {
      onConfirm(title)
    } else if (onCancel) {
      onCancel()
    }
    dispatch(hideModal())
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      {basic ? (
        content
      ) : (
        <>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            {Array.isArray(buttons) ? (
              buttons.map((button, indx) => {
                return (
                  <Button
                    key={indx}
                    onClick={() => {
                      this.props.dispatch(button.action())
                      this.props.dispatch(handleClose())
                    }}
                  >
                    {button.label}
                  </Button>
                )
              })
            ) : (
              <>
                {cancel !== false && (
                  <Button variant="outlined" color="default" onClick={handleClose}>
                    Cancel
                  </Button>
                )}
                <Button variant="outlined" color="primary" onClick={() => handleClose('ok')}>
                  {confirmLabel}
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default Modal
