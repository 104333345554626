import { createSlice } from '@reduxjs/toolkit'
import * as asyncActions from './socket.asyncActions'

const initialState = { connected: false }

const slice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    connected(state, action) {
      state.connected = true
      state.pending = false
    },
    disconnected(state, action) {
      state.connected = false
      state.pending = false
    },
  },
  extraReducers: {
    // asynchronous actions
    [asyncActions.checkSocket.pending]: (state, action) => {
      state.connected = false
      state.pending = true
    },
    [asyncActions.checkSocket.fulfilled]: (state, action) => {
      state.connected = action.payload
      state.pending = false
    },
  },
})

export default slice

export const { name, actions, reducer } = slice
