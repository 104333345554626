import React from 'react'
import pluralize from 'pluralize'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ClearIcon from '@material-ui/icons/Clear'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'

import Spacer from '../Spacer'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),

    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    width: 400,
  },
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const {
    actions,
    title,
    numSelected,
    deSelectAll,
    selected,
    hasSearch,
    onChange,
    showFilters,
    setShowFilters,
    selectedActions: SelectedActions,
  } = props
  const [search, setSearch] = React.useState('')

  const handleKeys = (event) => {
    if (event.key === 'Enter') {
      // Enter pressed - trigger the submit
      event.preventDefault()
      onChange({ search: search })
    }
  }
  const clearSearch = () => {
    setSearch('')
    onChange('')
  }
  const ActionsAfterTitle = actions?.filter((action) => action.position === 'afterTitle')
  const ActionsAtEnd = actions?.filter((action) => action.position === 'end')
  const ActionsAtStart = actions?.filter((action) => action.position === 'start')

  return (
    <Toolbar className={`${classes.root}${numSelected > 0 ? ` ${classes.highlight}` : ''}`}>
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" component="div" color="inherit">
          {numSelected} {pluralize('row', numSelected)} selected
        </Typography>
      ) : !!title ? (
        [
          <Typography
            className={!ActionsAfterTitle?.length ? classes.title : undefined}
            variant="h6"
            id="tableTitle"
            component="div"
            key="title"
          >
            {title}
          </Typography>,
          ActionsAfterTitle && ActionsAfterTitle.length
            ? [
                ActionsAfterTitle.map(({ render: Action }, index) => <Action key={`act-at-${index}`} />),
                <Spacer key="title-spacer" />,
              ]
            : null,
        ]
      ) : (
        [
          ActionsAfterTitle && ActionsAfterTitle.map(({ render: Action }, index) => <Action key={`act-at-${index}`} />),
          <Spacer key="title-spacer" />,
        ]
      )}

      {numSelected > 0 ? (
        SelectedActions && <SelectedActions selected={selected} deSelectAll={deSelectAll} />
      ) : (
        <>
          {ActionsAtStart && ActionsAtStart.map(({ render: Action }, index) => <Action key={index} />)}
          {hasSearch && (
            <>
              <TextField
                className={classes.search}
                id="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={handleKeys}
                InputProps={{
                  classes: { root: classes.searchField },
                  endAdornment: (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onChange({ search: search })}
                  disableElevation
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </div>
            </>
          )}
          {setShowFilters && (
            <Tooltip title="Filter list">
              <div>
                <Button
                  variant="contained"
                  color={showFilters ? 'primary' : 'default'}
                  onClick={() => setShowFilters(!showFilters)}
                  disableElevation
                  startIcon={<FilterListIcon />}
                >
                  Filters
                </Button>
              </div>
            </Tooltip>
          )}
          {ActionsAtEnd && ActionsAtEnd.map(({ render: Action }, index) => <Action key={index} />)}
        </>
      )}
    </Toolbar>
  )
}

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// }

export default EnhancedTableToolbar
