const initialState = false

const initReducer = (state = initialState, { type, payload }) => {
  // switch (type) {
  //   case INITIALIZE:
  //     return payload
  //   default:
  // }
  return state
}

export default initReducer
