import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Button, TextField, Paper, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { services } from '../store/feathers'

import { authFailed } from '../store/selectors/auth.selectors'

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  }),
)

function Login(props) {
  const classes = useStyles()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const dispatch = useDispatch()
  const loginFailed = useSelector(authFailed)

  function handleEmailChange(event) {
    setEmail(event.target.value)
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value)
  }

  function handleFormSubmit(event) {
    event.preventDefault()
    dispatch(services.auth.authenticate({ strategy: 'local', email, password })).then((res) => {
      // Reload the project info after login
      dispatch(services.project.find())
    })
  }
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleFormSubmit}>
          <TextField
            error={loginFailed}
            fullWidth
            id="email"
            type="text"
            label="Email Address"
            placeholder="email"
            margin="normal"
            onChange={handleEmailChange}
          />
          <TextField
            error={loginFailed}
            fullWidth
            id="password"
            type="password"
            label="Password"
            placeholder="Password"
            margin="normal"
            helperText={loginFailed ? 'Incorrect username or password' : ''}
            onChange={handlePasswordChange}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign in
          </Button>
        </form>
      </Paper>
    </main>
  )
}

export default Login
