import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  current: 'light',
}

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    // synchronous actions
    switchTheme(state, action) {
      state.current = action.payload
    },
    toggleTheme(state, action) {
      state.current = state.current === 'light' ? 'dark' : 'light'
    },
  },
})

export default slice

export const { name, actions, reducer } = slice
