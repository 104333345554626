import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  message: null,
  duration: 6000,
  // One of: info / error / warning / success
  severity: 'info',
  isOpen: false,
  // position defaults
  vertical: 'bottom',
  horizontal: 'right',
}

const slice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    // synchronous actions
    showSnack: (state, action) => {
      let payload = action.payload
      if (action.payload.type === 'FeathersError' || payload instanceof Error) {
        if (payload.message === 'No accessToken found in storage') {
          // Ignore missing access token
          return state
        } else if (payload.message === 'jwt expired') {
          payload = { message: 'Your session has expired, please login again.', severity: 'info' }
        } else {
          payload = { message: payload.message, severity: 'error' }
        }
      }
      Object.assign(state, { ...payload }, { isOpen: true })
    },
    hideSnack: (state, action) => ({ ...state, isOpen: false }),
  },
})

export default slice

export const { name, actions, reducer } = slice
