/**
 * capitalize - function that capitalizes the first character of a string,
 * and optionally forces the rest of the string characters to lowercase
 *
 * @param {string} str - String to capitalize
 * @param {boolean} [lowerRest=false] - Flag that forces the lower case characters on the rest of the string
 * @returns {string} - returns capitalized string
 */
export const capitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''))
