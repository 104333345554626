/* eslint-disable no-cond-assign */
function toVal(mix) {
  let k
  let y
  let str = ''

  if (typeof mix === 'string' || typeof mix === 'number') {
    str += mix
  } else if (typeof mix === 'object') {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += ' ')
            str += y
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix[k]) {
          str && (str += ' ')
          str += k
        }
      }
    }
  }

  return str
}

/**
 * classes - function that creates a class name from provided list of arrays / objects / strings
 * it iterates and checks for conditional properties
 * @example
 * classes('foo', {'bar': false}, {'done': true})
 * // outputs: foo true
 *
 * @param {...args} args - list of parameters to convert to string
 * @returns {string} - returns a space separated strings
 */
export const classes = (...args) => {
  let i = 0
  let tmp
  let x
  let str = ''
  while (i < args.length) {
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += ' ')
        str += x
      }
    }
  }
  return str
}
