import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AddIcon from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { formatDate } from '../utils/formatDate'
import { getUserId, isAdmin } from '../store/selectors/auth.selectors'
import { services } from '../store/feathers'
import { showModal } from '../store/modal'
import AddUserDialog from '../components/AddUser'
import DataTable from '../components/Table/DataTable'
import Page from '../components/Page'
import { getUsersQuery } from '../store/selectors/users.selectors'

const UsersActions = (props) => {
  const authId = useSelector(getUserId)
  const hasAdminRights = useSelector(isAdmin)
  const usersQuery = useSelector(getUsersQuery)
  const history = useHistory()
  const dispatch = useDispatch()

  const { record, setEditUser } = props

  const onEditUser = () => {
    if (authId === record._id) {
      history.push(`/account`)
    } else {
      setEditUser(record)
    }
  }

  const onDelete = () => {
    dispatch(
      showModal({
        title: 'Delete',
        content: <>Are you sure you want to delete user {record.name ? <b>{record.name} </b> : 'selected'}?</>,
        confirmLabel: 'Delete',
        onConfirm: () => {
          dispatch(services.users.remove(record._id)).then(() => {
            // On record delete reset the pagination (skip) to 0
            dispatch(services.users.find({ query: { ...usersQuery.query, $skip: 0 } }))
          })
        },
      }),
    )
  }

  return (
    <>
      <Tooltip title="Edit User">
        <span>
          <IconButton aria-label="Edit User" disabled={!hasAdminRights} onClick={onEditUser}>
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Delete User">
        <span>
          <IconButton aria-label="Delete User" disabled={!hasAdminRights} onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

const columnsDef = [
  { label: 'Name', field: 'name', hasFilter: true, isSortable: true },
  { label: 'Email', field: 'email', hasFilter: true, isSortable: true },
  {
    label: 'Created',
    field: 'createdAt',
    hasFilter: true,
    isSortable: true,

    render: (rowData) => {
      return formatDate(rowData.createdAt)
    },
  },
  {
    label: 'Updated',
    field: 'updatedAt',
    hasFilter: true,
    isSortable: true,

    render: (rowData) => {
      return formatDate(rowData.updatedAt)
    },
    defaultSort: 'desc',
  },
  {
    label: 'Is Admin',
    field: 'isAdmin',
    hasFilter: true,
    isSortable: true,

    render: (rowData) => {
      return rowData.isAdmin ? <Check /> : null
    },
  },
]

function Users() {
  const dispatch = useDispatch()
  const usersQuery = useSelector(getUsersQuery)

  // Add User dialog
  const [user, setAddEditUser] = React.useState(false)

  const handleAddEditDialog = (data) => {
    if (data) {
      // Strip all dynamically created fields and material-table injected properties
      const { _id, createdAt, updatedAt, tableData, ...user } = data
      // If _id is set, presume we are doing user edit
      if (_id) {
        dispatch(services.users.patch(_id, user)).then(() => {
          setAddEditUser(false)
          dispatch(services.users.find({ ...usersQuery }))
        })
      } else {
        dispatch(services.users.create(data)).then(() => {
          setAddEditUser(false)
          dispatch(services.users.find({ ...usersQuery }))
        })
      }
    } else {
      setAddEditUser(false)
    }
  }

  const columns = [
    ...columnsDef,
    {
      label: '',
      field: 'actions-edit',
      width: 1,
      nowrap: true,
      render: (record) => {
        return <UsersActions record={record} setEditUser={setAddEditUser} />
      },
    },
  ]

  return (
    <Page
      title="Manage Users"
      actions={[
        {
          title: 'Add User',
          icon: <AddIcon />,
          onClick: () => setAddEditUser(true),
        },
      ]}
    >
      <DataTable columns={columns} selectable={false} service="users" size="small" withFilters={true} />
      <AddUserDialog open={!!user} user={user} onClose={handleAddEditDialog} />
    </Page>
  )
}

export default Users
