import * as selectors from './overlay.selectors'
import slice from './overlay.slice'

export const {
  name,
  actions: { showOverlay, hideOverlay },
  reducer,
} = slice

export const { overlayVisible } = selectors
