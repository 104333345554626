import { makeStyles } from '@material-ui/core/styles'

export const useGlobalStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      overflow: 'hidden',
    },
    '#root': {
      height: '100%',
      // overflow: 'auto',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    // Fixing z-index for popovers
    '#mouse-over-popover': {
      zIndex: 1000,
    },
    // '[data-tooltip]': {
    //   position: 'relative',
    // },

    // '[data-tooltip]:before, [data-tooltip]:after': {
    //   position: 'absolute',
    //   visibility: 'hidden',
    //   transition:
    //     'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24)',
    //   transitionDelay: 0,
    //   transform: 'translate3d(0, 0, 0)',
    //   pointerEvents: 'none',
    //   bottom: '100%',
    //   left: '50%',
    // },

    // '[data-tooltip]:hover:before, [data-tooltip]:hover:after,[data-tooltip]:focus:before, [data-tooltip]:focus:after': {
    //   transitionDelay: '2s',
    //   opacity: 1,
    //   visibility: 'visible',
    //   transform: 'translateY(-8px)',
    // },

    // '[data-tooltip]:before': {
    //   zIndex: 1001,
    //   border: '6px solid transparent',
    //   background: 'transparent',
    //   content: '""',
    //   marginLeft: -6,
    //   marginBottom: -11,
    //   borderTopColor: '#fff',
    // },

    // '[data-tooltip]:after': {
    //   zIndex: 1000,
    //   padding: 8,
    //   width: 100,
    //   backgroundColor: '#fff',
    //   border: '1px solid #9e9e9e',
    //   color: 'rgba(0, 0, 0, 0.87)',
    //   content: 'attr(data-tooltip)',
    //   fontSize: 14,
    //   lineHeight: 1.2,
    //   marginLeft: -50,
    //   boxShadow:
    //     '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    // },

    /* The emerging W3C standard that is currently Firefox-only */
    '[data-scrollbars]': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#0277bd',
    },

    /* Works on Chrome/Edge/Safari */
    '[data-scrollbars]::-webkit-scrollbar': {
      width: 6,
      height: 6,
    },

    '[data-scrollbars]::-webkit-scrollbar-track': {
      background: '#fff',
    },

    '[data-scrollbars]::-webkit-scrollbar-thumb': {
      backgroundColor: '#0277bd',
      borderRadius: 0,
      border: 0,
    },
  },
}))
