import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import ClearIcon from '@material-ui/icons/Clear'
import FilterIcon from '@material-ui/icons/FilterList'
import { fastDeepEqual } from '../../utils/object/fastDeepEqual'
import { classes as cl } from '../../utils/classes'
import DateFilter from '../Filters/Date'

const useStyles = makeStyles((theme) => ({
  applyButton: {
    marginLeft: theme.spacing(2),
  },
  filterCell: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    '& > div': {
      flexGrow: 1,
    },
  },
  clear: {
    cursor: 'pointer',
  },
  hidden: {
    opacity: 0,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

const DynamicFilter = (props) => {
  const { filters, filterField, col, classes, onEnter } = props
  if (!col.hasFilter) return null
  switch (col.type) {
    case 'date': {
      const onChange = (value) => {
        filterField(col.field, value)
      }
      return <DateFilter field={col.field} value={filters[col.field]} onChange={onChange} />
    }
    case 'boolean':
    case 'exists':
    case 'enum': {
      const onChange = (event) => filterField(col.field, event.target.value)
      const items =
        col.type === 'exists'
          ? [
              { key: 'True', value: '$notNull' },
              { key: 'False', value: '$null' },
            ]
          : col.type === 'boolean'
          ? [
              { key: 'True', value: '$true' },
              { key: 'False', value: '$false' },
            ]
          : Array.isArray(col.enum)
          ? col.enum.map((item) => (typeof item === 'object' ? item : { key: item, value: item }))
          : []
      return (
        <Select value={filters[col.field] || ''} onChange={onChange}>
          <MenuItem value="">&nbsp;</MenuItem>
          {items.map(({ key, value }) => (
            <MenuItem key={key} value={value}>
              {key}
            </MenuItem>
          ))}
        </Select>
      )
    }
    default: {
      return (
        <TextField
          type="text"
          size="small"
          onChange={(event) => filterField(col.field, event.target.value)}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault()
              onEnter()
            }
          }}
          value={filters[col.field] || ''}
          InputProps={{
            endAdornment: (
              <ClearIcon
                fontSize="small"
                className={cl(classes.clear, !filters[col.field] && classes.hidden)}
                onClick={() => {
                  filterField(col.field, '')
                }}
              />
            ),
          }}
        />
      )
    }
  }
}

const FiltersRow = (props) => {
  const classes = useStyles()
  const { columns, selectable, hasDetails, filter, setShowFilters } = props
  const [filters, setFilters] = React.useState(typeof showFilters === 'object' ? filter : {})

  const disabled = (!filter && Object.keys(filters).length === 0) || fastDeepEqual(filter, filters)

  const filterField = (field, val) => {
    // const col = columns.find((c) => c.field === field)
    const _filters = { ...filters }
    if (val === '') {
      if (_filters[field]) {
        delete _filters[field]
      }
    } else {
      _filters[field] = val
    }
    setFilters(_filters)
  }

  const onSubmit = () => {
    setShowFilters({ ...filters })
  }

  React.useEffect(() => {
    setFilters(filter || {})
  }, [filter])

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      {hasDetails && <TableCell />}
      {selectable && <TableCell />}
      {columns.map((col, index) => (
        <TableCell
          key={index}
          align={col.align || 'left'}
          className={index === columns.length - 1 ? classes.nowrap : undefined}
        >
          <div className={classes.filterCell}>
            <DynamicFilter col={col} filters={filters} filterField={filterField} classes={classes} onEnter={onSubmit} />
            {index === columns.length - 1 ? (
              <div>
                <Button
                  className={classes.applyButton}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={disabled}
                  onClick={onSubmit}
                  startIcon={<FilterIcon />}
                  disableElevation
                >
                  Apply
                </Button>
              </div>
            ) : null}
          </div>
        </TableCell>
      ))}
    </TableRow>
  )
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// }

export default FiltersRow
