/**
 * isNumber checks if a given value is a properly formatted integer number
 * @example
 * // True:
 *   isNumber("1")
 *   isNumber(1e10)
 *   isNumber(1E10)
 *   isNumber(+"6e4")
 *   isNumber("1.2222")
 *   isNumber("-1.2222")
 *   isNumber(1)
 *   isNumber(0)
 *   isNumber(-0)
 *   isNumber(1010010293029)
 *   isNumber(1.100393830000)
 *   isNumber(Math.LN2)
 *   isNumber(Math.PI)
 *   isNumber(5e10)
 * // False:
 *   isNumber("-1.222200000000000000")
 *   isNumber("1.222200000000000000")
 *   isNumber(NaN)
 *   isNumber(Infinity)
 *   isNumber(-Infinity)
 *   isNumber()
 *   isNumber(undefined)
 *   isNumber('[1,2,3]')
 *   isNumber({a:1,b:2})
 *   isNumber(null)
 *   isNumber([1])
 *   isNumber(new Date())
 *
 * @param {String | Number} val - value to check
 * @returns {Boolean} - true or false
 */
export const isNumber = (val) =>
  !Array.isArray(val) && String(val) === String(Number(val)) && Number.isFinite(Number(val))
