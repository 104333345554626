import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Array to be consumed with Object.fromEntries to quickly create cloned initialState
const initialState = (idField, sort = { [idField]: -1 }) => [
  ['error', null],
  ['fetchedAt', null],
  ['filter', null],
  ['find', null],
  ['get', null],
  ['idField', idField],
  ['limit', 20],
  ['query', null],
  ['skip', 0],
  ['sort', sort],
  ['status', null],
  ['isSaving', null],
  ['total', 0],
]

const withError = (errorHandler, payloadCreator) => {
  return async (args, thunkAPI) => {
    try {
      return await payloadCreator(args, thunkAPI)
    } catch (err) {
      thunkAPI.dispatch(errorHandler(err))
      // throw error so createAsyncThunk will dispatch '/rejected'-action
      throw err
    }
  }
}

const updateStateObject = ({ error = null, status, fetchedAt, method, action, initialState, state, idField }) => {
  if (method === 'remove') return { error, status }
  const stateMethod = ['patch', 'update'].includes(method) ? 'get' : method
  // InitialState is provided only on initialization, any subsequent request will omit it
  if (initialState) {
    // This should always be true, but leave it here for any future extensions
    if (status !== 'pending') {
      console.error('THIS SHOULD NOT BE PRINTED IN ANY TIME')
      Object.assign(state, { [stateMethod]: { ...initialState[stateMethod] }, error, status })
    } else if (stateMethod !== method) {
      Object.assign(state, { status, isSaving: true })
    } else {
      // Object.assign(state, { status })
      Object.assign(state, { [stateMethod]: initialState[stateMethod], error, status })
    }
  } else {
    // Handle find with disabled pagination
    if (method === 'find') {
      // Handle all possible result types (array, object or primitive) and make a shallow copy
      const data = Array.isArray(action.payload)
        ? [...action.payload]
        : Array.isArray(action.payload.data)
        ? [...action.payload.data]
        : typeof action.payload === 'object'
        ? { ...action.payload }
        : action.payload
      const { total, limit, skip, filter, sort } = action.payload
      Object.assign(state, {
        [stateMethod]: data,
        error,
        fetchedAt,
        filter,
        limit,
        // Persist query as it should be changed only directly with actions
        query: state.query,
        skip,
        sort,
        status,
        total,
      })
    } else {
      Object.assign(state, {
        [stateMethod]: { ...action.payload },
        error,
        status,
        fetchedAt,
        // Set isSaving flag for patch/update calls
        ...(method !== stateMethod ? { isSaving: status === 'pending' } : {}),
      })
    }
  }
  // Check for updates and if we have list (find result) try to find updated record and update it there as well
  if (status === 'fulfilled' && method !== stateMethod) {
    // TODO: improve this
    const findResponse = state.find ? (Array.isArray(state.find) ? state.find : state.find.data) : null
    const found = findResponse?.find((rec) => rec[idField] === action.payload[idField])
    if (found) {
      Object.assign(found, action.payload)
    }
  }
}

// Generate all the thunk methods, actions and reducers for a single service (get/find/patch/update/remove)
const serviceThunks = (name, initialState, api, methods = api.methods, errorHandler, idField) => {
  const service = {}
  const extraReducers = methods.reduce((acc, method) => {
    const NAMESPACE = `service/${name}/${method}`
    const payloadCreator = async (data, thunkAPI) => {
      return await api.service(name)[method](...data)
    }
    const thunk = createAsyncThunk(NAMESPACE, errorHandler ? withError(errorHandler, payloadCreator) : payloadCreator)
    // Collect all of the thunks in a single object that can later be used as : obj.find()
    // wrap thunk call with dynamic arguments which are expanded in the api.service call
    // this is needed to mimic service parameters while creatAsyncThunk has only one `data` argument
    // service[method] = thunk
    service[method] = (...args) => {
      return thunk(args)
    }

    Object.assign(acc, {
      [thunk.pending]: (state, action) => {
        updateStateObject({
          status: 'pending',
          method,
          initialState,
          state,
          idField,
        })
      },
      [thunk.fulfilled]: (state, action) => {
        updateStateObject({ status: 'fulfilled', fetchedAt: new Date().getTime(), method, action, state, idField })
      },
      [thunk.rejected]: (state, action) => {
        Object.assign(state, {
          error: action.payload,
          status: 'rejected',
          isSaving: null,
        })
      },
    })
    return acc
  }, {})
  return [service, extraReducers]
}

export const reduxFeathers = ({
  api,
  services,
  reducers = {},
  methods = {},
  initialStates = {},
  handleErrors = false,
  idFields = {},
  defaultSorts = {},
}) => {
  if (
    handleErrors &&
    !(handleErrors instanceof Function || (typeof handleErrors === 'object' && handleErrors !== null))
  ) {
    throw new Error('handleErrors parameter must be Function or object')
  }
  const asyncActions = {}
  const slices = services.map((name) => {
    const idField = idFields[name] || '_id'
    const state = initialStates[name] || initialState(idField, defaultSorts[name])
    const errorHandler = handleErrors ? (handleErrors instanceof Function ? handleErrors : handleErrors[name]) : false
    const initial = Object.fromEntries(
      // state.map(([key, val]) => [key, val === null ? null : typeof val === 'string' ? val : { ...val }]),
      state.map(([key, val]) => [
        key,
        Array.isArray(val) ? [...val] : val === null ? null : typeof val === 'object' ? { ...val } : val,
      ]),
    )
    const [service, extraReducers] = serviceThunks(name, initial, api, methods[name], errorHandler, idField)
    asyncActions[name] = service

    return createSlice({
      name,
      initialState: initial,
      reducers: reducers[name],
      extraReducers,
    })
  })

  return [asyncActions, slices]
}

const initialAuthState = {
  error: null,
  status: null,
  user: null,
  accessToken: null,
  authentication: null,
}

export const reduxFeathersAuth = (api, reducers, errorHandler) => {
  const payloadCreator = async (data, thunkAPI) => {
    const response = data ? await api.authenticate(data) : api.reAuthenticate()
    return response
  }
  const authenticate = createAsyncThunk(
    'auth/create',
    errorHandler ? withError(errorHandler, payloadCreator) : payloadCreator,
  )

  const slice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
      // synchronous actions
      logout(state, action) {
        api.logout()
        Object.assign(state, { ...initialAuthState, status: 'logged-out' })
      },
      ...(reducers ? reducers : {}),
    },
    extraReducers: {
      [authenticate.pending]: (state, action) => {
        Object.assign(state, {
          error: null,
          status: 'pending',
          user: null,
        })
      },
      [authenticate.fulfilled]: (state, action) => {
        Object.assign(state, {
          error: null,
          status: 'fulfilled',
          ...action.payload,
        })
      },
      [authenticate.rejected]: (state, action) => {
        // Set status to rejected on local strategy execution
        // when jwt strategy fails set it to logged-out so we can distinguish this two
        Object.assign(state, {
          error: action?.error?.message || 'Authentication error',
          status: action.meta?.arg ? 'rejected' : 'logged-out',
        })
      },
    },
  })
  const {
    actions: { logout },
  } = slice
  return [{ authenticate, logout }, slice]
}
