import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import { reducer as modalReducer } from './modal'
import { reducer as overlayReducer } from './overlay'
import { reducer as snackReducer } from './snack'
import { reducer as socketReducer } from './socket'
import { reducer as themeReducer } from './theme'
import { slices } from './feathers'
import initReducer from './init/init.reducer'

// Convert slices array to an object formatted structure that combineReducers recognize ex: {users: function() {}}
const feathersReducers = slices.reduce((acc, { name, reducer }) => {
  acc[name] = reducer
  return acc
}, {})

const combinedReducers = combineReducers({
  // Merge all reducers
  init: initReducer,
  modal: modalReducer,
  snack: snackReducer,
  socket: socketReducer,
  theme: themeReducer,
  overlay: overlayReducer,
  ...feathersReducers,
})

const rootReducer = (state, action) => {
  // Reset entire state to initial values when user logs out
  if (action.type === 'auth/logout') {
    state = undefined
  }
  return combinedReducers(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    // Serialization makes a hell of errors, especially since we are storing
    // components in the store for the modal component
    serializableCheck: false,
    // This is slowing down the store in dev mode
    immutableCheck: false,
    // serializableCheck: {
    //   // Ignore these action types (showModal action can have components and functions - don't serialize it)
    //   ignoredActions: ['modal/showModal'],
    //   // Ignore these field paths in all actions
    //   // ignoredActionPaths: ['modal.params'],
    //   // Ignore these paths in the state
    //   // ignoredPaths: ['modal.params'],
    //   ignoredPaths: ['modal'],
    // },
  }),
})

export default store
