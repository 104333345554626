import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

// import CachedIcon from '@material-ui/icons/Cached'
import SvgIcon from '@material-ui/core/SvgIcon'
import SettingsIcon from '@material-ui/icons/Settings'
import BugReportIcon from '@material-ui/icons/BugReport'
import ToggleDarkModeIcon from '@material-ui/icons/Brightness4Outlined'
import ToggleLightModeIcon from '@material-ui/icons/Brightness4Outlined'
// import Users from '@material-ui/icons/RecentActors'
import WalletIcon from '@material-ui/icons/AccountBalanceWallet'
import PeopleIcon from '@material-ui/icons/People'
import TestsIcon from '../assets/svgIcons/test-tube.svg'
import InvalidTestsIcon from '../assets/svgIcons/test-tube-off.svg'

import Page from '../components/Page'
import { getProjectInfo } from '../store/selectors/project.selectors'
import { toggleTheme } from '../store/theme'
import DashboardCard from '../components/DashboardCard'

const RESET_STORAGE = 'RESET_STORAGE'
const THEME_CHANGE = 'THEME_CHANGE'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  boxContainer: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(3),
  },
  gutter: {
    padding: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(),
  },
  fabButton: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
}))

function About({ isDarkMode, onThemeChange, onResetRequested }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const projectInfo = useSelector(getProjectInfo)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (type) => {
    setAnchorEl(null)
    if (type === THEME_CHANGE) {
      dispatch(toggleTheme())
      // onThemeChange()
    } else if (type === RESET_STORAGE) {
      // onResetRequested()
    }
  }

  return (
    <Page>
      <Box display="block" marginBottom="2rem" marginTop="1rem">
        {projectInfo && (
          <>
            <Grid className={classes.boxContainer} container spacing={3}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DashboardCard
                  title="Tests"
                  count={projectInfo.testsCount}
                  recent={projectInfo.recentTestsCount}
                  icon={<SvgIcon component={TestsIcon} viewBox="0 0 24 24" />}
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DashboardCard
                  title="Invalid Tests"
                  count={projectInfo.invalidTestsCount}
                  recent={projectInfo.recentInvalidTestsCount}
                  icon={<SvgIcon component={InvalidTestsIcon} viewBox="0 0 24 24" />}
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DashboardCard
                  title="Portfolios"
                  count={projectInfo.portfoliosCount}
                  recent={projectInfo.recentPortfoliosCount}
                  icon={<WalletIcon />}
                />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DashboardCard
                  title="Users"
                  count={projectInfo.usersCount}
                  recent={projectInfo.recentUsersCount}
                  icon={<PeopleIcon />}
                />
              </Grid>
            </Grid>
            <Card className={classes.gutter}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Project Information
                </Typography>
                <TableContainer component={Paper} className={classes.topMargin}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.bold}>Name</TableCell>
                        <TableCell>{projectInfo?.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.bold}>Description</TableCell>
                        <TableCell>{projectInfo?.description}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.bold}>License</TableCell>
                        <TableCell>{projectInfo?.license}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.bold}>Version</TableCell>
                        <TableCell>{projectInfo?.version}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.bold}>Release Date</TableCell>
                        <TableCell>{projectInfo?.releaseDate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.bold}>Git Commit Count</TableCell>
                        <TableCell>{projectInfo?.gitCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.bold}>Git Commit</TableCell>
                        <TableCell>
                          <Link
                            target="_blank"
                            to={'//gitlab.ics.com/rhampton/Quidel-PCR-Cloud-Portal/-/commit/' + projectInfo?.gitCommit}
                          >
                            {projectInfo?.gitCommit}
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </>
        )}
        <Card className={classes.gutter}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Test Data Management
            </Typography>
            <Typography variant="body1" component="ul" className={classes.topMargin}>
              <ul>
                <li>Create new portfolios</li>
                <li>Delete existing portfolios</li>
                <li>Modify existing portfolios (name, creator, description)</li>
                <li>Add test runs to any portfolio</li>
                <li>Navigate to a portfolios curve information for further analysis</li>
                <li>Note: Only your own portfolios and those marked public are visible to you</li>
              </ul>
            </Typography>
          </CardContent>
          <CardActions>
            <Link to="/portfolios">
              <Button variant="outlined">Proceed to Portfolios</Button>
            </Link>
          </CardActions>
        </Card>
      </Box>
      <Fab className={classes.fabButton} color="primary" aria-label="add" onClick={handleClick}>
        <SettingsIcon />
      </Fab>
      <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => handleClose()}>
        <MenuItem onClick={() => handleClose(THEME_CHANGE)}>
          <ListItemIcon>{isDarkMode ? <ToggleDarkModeIcon /> : <ToggleLightModeIcon />}</ListItemIcon>
          <ListItemText primary="Toggle Theme" />
        </MenuItem>
        <Link to="//trello.com/b/Rf5pRmPA" target="_blank">
          <MenuItem onClick={() => handleClose()}>
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body1" component="p" color="primary">
                Report Bug
              </Typography>
            </ListItemText>
          </MenuItem>
        </Link>
      </Menu>
    </Page>
  )
}

export default About
