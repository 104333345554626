export const isPending = (state) => state.portfolios.status === 'pending'
export const isSaving = (state) => state.portfolios.isSaving
// export const isStale = (state) => state.find === null
export const getShowAll = (state) => !!state.portfolios?.query?.showAll
export const getPortfolio = (state) => state.portfolios.get
export const getPortfolios = (state) => state.portfolios.find || []
export const getPortfolioWithData = (state) => (state.portfolios?.get?.isWithData ? state.portfolios.get : null)
export const getPortfoliosQuery = (state) => ({
  query: {
    // load filter only if set
    ...(state.portfolios.filter && Object.keys(state.portfolios.filter) ? { $filter: state.portfolios.filter } : {}),
    // load additional query only if set
    ...(state.portfolios.query && Object.keys(state.portfolios.query) ? state.portfolios.query : {}),
    $sort: state.portfolios.sort,
    $limit: state.portfolios.limit,
    $skip: state.portfolios.skip,
  },
})
