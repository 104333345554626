export const getUser = (state) => (state.users.status === 'fulfilled' && state.users.get ? state.users.get : null)
export const getUsersQuery = (state) => ({
  query: {
    // load filter only if set
    ...(state.users.filter && Object.keys(state.users.filter) ? { $filter: state.users.filter } : {}),
    // load additional query only if set
    ...(state.users.query && Object.keys(state.users.query) ? state.users.query : {}),
    $sort: state.users.sort,
    $limit: state.users.limit,
    $skip: state.users.skip,
  },
})
