export const curveMetricsPageStyles = (theme) => ({
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    minWidth: '100%',
  },
  curve: {
    flexGrow: 1,
    '& canvas': {
      width: '100% !important',
    },
    margin: theme.spacing(1),
  },
  pushRight: {
    marginRight: theme.spacing(),
  },
  savePortfolioButton: {
    marginTop: theme.spacing(2),
  },
  scaleGrid: {
    borderTop: `solid 1px ${theme.palette.grey[500]}`,
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
    paddingTop: theme.spacing(2),
    fontVariant: 'small-caps',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'rgb(0 0 0 / 54%)',
    '& > div > div': {
      marginRight: theme.spacing(),
    },
  },
  flex: {
    display: 'flex',
  },
  'flex-align-items-center': {
    alignItems: 'center',
  },
  'm-1': {
    margin: theme.spacing(1),
  },
  'w-175px': {
    width: '175px',
  },
  inlineSaveBtn: {
    marginRight: theme.spacing(1),
  },
  toggleLegend: {
    '&:first-child': {
      marginLeft: 36,
    },
    '&:not(:first-child)': {
      marginRight: 'auto',
    },
  },
})
