export const authFinalized = (state) => state.auth.status && state.auth.status !== 'pending'
export const authFailed = (state) => state.auth.status === 'rejected'
export const isAuthenticated = (state) => state?.auth?.user !== null
export const isAdmin = (state) => state?.auth?.user?.isAdmin
export const getEmail = (state) => state?.auth?.user?.email

export const getAvatar = (state) => state?.auth?.user?.avatar
export const getName = (state) => state?.auth?.user?.name
// export const getUser = (state) => state?.auth?.user
export const getUserId = (state) => state?.auth?.user._id
// export const isNotAuthenticated = (state) => !state?.auth?.isSignedIn
