import React from 'react'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import BoxCheckedIcon from '@material-ui/icons/CheckBox'
import BoxUnCheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckedIcon from '@material-ui/icons/CheckCircleOutline'
import UnCheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import { classes as cl } from '../utils/classes'
import { debounce } from '../utils/debounce'
import { formatDate } from '../utils/formatDate'
import { DYES, CHAMBERS, CHANNELS, getDye } from '../utils/chartConfig'
import {
  handleChamberClick,
  handleChannelClick,
  handleSingleClick,
  handleLabelClick,
  handleMaskClick,
  handleAllMaskClick,
  handleLabelMaskClick,
  handleAllTestMasks,
  handleSelectAll,
} from '../utils/gridFunctions'
import { useSelectionGridStyles } from '../utils/styles/selectionGridStyles'

const SelectionGrid = (props) => {
  const classes = useSelectionGridStyles()
  const { testsData = [], tests = [], setTestsData, masks, setMasks } = props

  const [scrollBarWidth, setScrollbarWidth] = React.useState(false)
  const [popover, setPopover] = React.useState(null)
  const scrollContainer = React.useRef()

  const debouncedCheck = debounce(() => {
    if (scrollContainer.current?.firstElementChild) {
      const width = scrollContainer.current.offsetWidth - scrollContainer.current.firstElementChild.offsetWidth
      if (scrollBarWidth !== width) {
        setScrollbarWidth(width)
      }
    }
  })

  React.useLayoutEffect(() => {
    // We need to wrap calculation inside animation frame so that the browser has time to update values
    const raf = requestAnimationFrame(debouncedCheck)
    return () => cancelAnimationFrame(raf)
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', debouncedCheck)

    return () => {
      window.removeEventListener('resize', debouncedCheck)
    }
  })

  let popoverTimeout

  const handlePopoverOpen = (anchorEl, test, dye, channel, chamber) => {
    if (popover) {
      setPopover(null)
    }
    if (popoverTimeout) {
      clearTimeout(popoverTimeout)
    }
    popoverTimeout = setTimeout(() => {
      // Make sure mouse is still over the same element, otherwise just ignore the event
      if (Array.from(document.querySelectorAll(':hover')).includes(anchorEl))
        setPopover({ anchorEl, test, dye, channel, chamber })
    }, 1000)
  }

  const handlePopoverClose = () => {
    if (popoverTimeout) {
      clearTimeout(popoverTimeout)
    } else if (popover) {
      setPopover(null)
    }
  }

  const open = Boolean(popover)
  const allTestsMasked = testsData.filter((t) => t.isMasked).length === testsData.length
  const scrollBarPadding = { ...(scrollBarWidth ? { style: { paddingLeft: `${scrollBarWidth}px` } } : {}) }

  return (
    <div className={classes.root}>
      <div className={classes.header} {...scrollBarPadding}>
        <div className={classes.row}>
          <div key="all">
            <span
              className={`${classes.selectAll} ${classes.textBlue} ${classes.pointer}`}
              onClick={() => handleSelectAll(masks, tests, testsData, setTestsData)}
            >
              Select all
            </span>
          </div>
          <div className={classes.headerSpacer} />
          {[...CHAMBERS].map((chamber, index) => (
            <div
              key={chamber}
              className={`${classes.pointer} ${classes.textBlue}`}
              onClick={() => handleChamberClick(chamber, masks, testsData, setTestsData)}
            >{`Chamber ${chamber}`}</div>
          ))}
        </div>
        <div className={classes.row}>
          <div className={classes.labelCell}></div>
          <div></div>
          {[...CHANNELS].map((channel, index) => (
            <div
              className={`${classes.textCenter} ${classes.pointer} ${classes.textBlue}`}
              key={channel}
              onClick={() => handleChannelClick(channel, testsData, setTestsData)}
            >
              {getDye(channel)}
            </div>
          ))}
        </div>
        <div className={`${classes.row} ${classes.maskRow}`}>
          <div
            key="masks"
            className={`${classes.labelCell} ${classes.textCenter} ${classes.pointer} ${classes.textBlue}`}
            onClick={() => handleAllMaskClick(masks, setMasks)}
          >
            Masks
          </div>
          <div
            key="masks-icons"
            className={testsData.length ? classes.noBb : undefined}
            onClick={() => handleAllTestMasks(allTestsMasked, testsData, setTestsData)}
          >
            {allTestsMasked ? (
              <CheckedIcon className={classes.pointer} />
            ) : (
              <UnCheckedIcon className={classes.pointer} />
            )}
          </div>
          {[...CHANNELS].map((channel) => (
            <div
              className={cl({ [classes.borderRight]: channel < 13 && !(channel % 4) })}
              key={channel}
              onClick={() => handleMaskClick(channel, masks, setMasks)}
            >
              {masks.includes(channel) ? (
                <BoxCheckedIcon className={classes.pointer} />
              ) : (
                <BoxUnCheckedIcon className={classes.pointer} />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.body}>
        <div ref={scrollContainer} data-scrollbars className={classes.legendScrollOverlay}>
          {testsData.map((testData, index) => {
            const test = tests.find(({ UUID }) => UUID === testData.UUID)
            return (
              <div key={index} className={classes.row}>
                <div key="sampleInfo1Value" className={`${classes.row} ${classes.labelCell}`}>
                  <span> {`${index + 1}.`}</span>
                  <span
                    className={`${classes.nameLabel} ${classes.textBlue} ${classes.pointer}`}
                    onClick={() => {
                      handleLabelClick(testData, masks, testsData, setTestsData)
                      handlePopoverClose()
                    }}
                    onMouseEnter={(event) => {
                      handlePopoverOpen(event.currentTarget, test)
                    }}
                    onMouseLeave={handlePopoverClose}
                  >
                    <bdi>
                      {`${test.meta.sampleInfo1Value}`}
                    </bdi>
                  </span>
                </div>
                <div
                  key="labelIcons"
                  className={cl(classes.maskRow, { [classes.bottomMask]: testsData.length === index + 1 })}
                  onClick={() => {
                    handleLabelMaskClick(testData, testsData, setTestsData)
                  }}
                >
                  {testData.isMasked ? (
                    <BoxCheckedIcon className={classes.pointer} />
                  ) : (
                    <BoxUnCheckedIcon className={classes.pointer} />
                  )}
                </div>
                {[...CHANNELS].map((channel) => {
                  const chamber = Math.ceil(channel / 4)
                  const residue = channel % 4
                  const channelId = residue ? residue - 1 : 3
                  const dye = DYES[channelId]
                  return (
                    <div
                      className={cl({ [classes.borderRight]: channel < 13 && !residue })}
                      key={channel}
                      onClick={() => {
                        handleSingleClick(channel, testData, testsData, setTestsData)
                      }}
                      onMouseEnter={(event) => {
                        handlePopoverOpen(event.currentTarget, test, dye, channelId + 1, chamber)
                      }}
                      onMouseLeave={handlePopoverClose}
                    >
                      {testData.channels.includes(channel) ? (
                        <CheckedIcon className={classes.pointer} />
                      ) : (
                        <UnCheckedIcon className={classes.pointer} />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
      {open && (
        <Popper
          id="mouse-over-popover"
          open={open}
          anchorEl={popover?.anchorEl}
          onClose={handlePopoverClose}
          modifiers={{
            flip: {
              enabled: true,
            },
            offset: { enabled: true, offset: '0, 10' },
          }}
        >
          <Paper className={cl(classes.paper, { [classes.cell]: popover.dye })}>
            <div>
              <b>Instrument ID:</b> {popover.test.instrumentId}
            </div>
            <div>
              <b>Date:</b> {formatDate(popover.test.date)}
            </div>
            <div>
              <b>Operator:</b> {popover.test.meta.operator}
            </div>
            <div>
              <b>Sample Info:</b> {popover.test.meta.sampleInfo1Value}
            </div>
            {popover.dye && (
              <div>
                <hr />
                <div>
                  <b>Chamber: </b>
                  {popover.chamber}
                </div>
                <div>
                  <b>Dye / Channel:</b> {popover.dye} / {popover.channel}
                </div>
              </div>
            )}
          </Paper>
        </Popper>
      )}
    </div>
  )
}

export default SelectionGrid
