import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { selectSnack, hideSnack } from '../../store/snack'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Snack = () => {
  const { isOpen, message, duration, severity, vertical, horizontal } = useSelector(selectSnack)
  const dispatch = useDispatch()
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(hideSnack())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      message={message}
      autoHideDuration={duration || 5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Snack
