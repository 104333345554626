import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { classes as cl } from '../utils/classes'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    height: 56,
    width: 56,
    backgroundColor: theme.palette.info.dark,
  },
  differenceValue: {
    marginRight: theme.spacing(1),
  },
}))

const DashboardCard = ({ className, title, count, recent, icon: Icon, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={cl(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {count}
            </Typography>
          </Grid>
          {Icon && (
            <Grid item>
              <Avatar className={classes.avatar}>{Icon}</Avatar>
            </Grid>
          )}
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography className={classes.differenceValue} variant="body2">
            {recent}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Since last month
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default DashboardCard
