import React from 'react'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

import { deepClone } from '../utils/object'
import { formatDate } from '../utils/formatDate'
import { getUser } from '../store/selectors/users.selectors'
import { getUserId } from '../store/selectors/auth.selectors'
import { services } from '../store/feathers'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
  },
  leftPart: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flexBasis: '30%',
  },
  avatar: {
    height: 100,
    width: 100,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

const notEqual = (usr1, usr2) => {
  if (!usr1 || !usr2) return false
  const keys = ['name', 'email', 'password', 'password2']
  for (const key of keys) {
    // If the values differs return true (take care of missing properties and undefined / empty values)
    if (usr1[key] !== usr2[key] && !(!usr1[key] && !usr2[key] && !!usr1[key] === !!usr2[key])) {
      return true
    }
  }
  return false
}

const Account = (props) => {
  let title
  let subtitle
  const classes = useStyles()
  const dispatch = useDispatch()
  const authId = useSelector(getUserId)
  const user = useSelector(getUser)
  const {
    match: {
      params: { userId = authId },
    },
  } = props

  if (authId === userId) {
    title = 'Edit your profile'
    subtitle = `Password field is ignored unless filled.`
  } else {
    title = 'Edit user'
    subtitle = 'Only changed details will be updated'
  }

  const [localUser, setLocalUser] = React.useState(
    user ? deepClone(user) : { name: '', email: '', password: '', password2: '' },
  )
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    // Load user record if its not already in the store
    if (!user && userId) {
      dispatch(services.users.get(userId))
    }
  }, [])

  // Update local state when redux state was changed - but remove passwords
  React.useEffect(() => {
    if (user) setLocalUser({ ...localUser, password: '', password2: '', ...user })
  }, [user])

  const handleChange = ({ target: { name: fieldName, value } }) => {
    const fieldToCheck = fieldName === 'password2' ? 'password' : fieldName
    if (errors[fieldToCheck]) {
      delete errors[fieldToCheck]
      setErrors(errors)
    }
    setLocalUser({
      ...localUser,
      [fieldName]: value,
    })
  }

  const validateFields = () => {
    let hasErrors
    const tmpErrors = {}
    if (!localUser.name) {
      tmpErrors.name = 'Full name can not be empty'
      hasErrors = true
    }
    if (!localUser.email) {
      tmpErrors.email = 'Email is a required field'
      hasErrors = true
    }
    if (localUser.password !== localUser.password2) {
      tmpErrors.password = 'Passwords must match'
      hasErrors = true
    }
    if (hasErrors) {
      setErrors({
        ...tmpErrors,
      })
      return false
    }
    return true
  }

  const saveChanges = () => {
    if (validateFields()) {
      const { password, password2, ...userObject } = localUser
      if (password && password2) {
        Object.assign(userObject, { password })
      }
      dispatch(services.users.patch(user._id, userObject))
    }
  }

  const profileChanged = notEqual(user, localUser)

  return (
    <Container maxWidth="md" className={classes.container}>
      <Card>
        <CardHeader subheader={subtitle} title={title} />
        <Divider />
        <CardContent>
          <Box display="flex" flexDirection="row">
            <Box className={classes.leftPart} alignItems="center" display="flex" flexDirection="column">
              {localUser.avatar ? (
                <Avatar className={classes.avatar} alt={name} src={localUser.avatar} />
              ) : (
                <AccountCircleIcon />
              )}
              <Typography className={classes.nowrap} color="textPrimary" gutterBottom variant="h6">
                {user?.name}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {`${user?.email}`}
              </Typography>
              <Typography className={classes.nowrap} color="textSecondary" variant="body1">
                {user && formatDate(user.createdAt)}
              </Typography>
            </Box>
            <Box flexGrow={1}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name}
                    label="Full name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={localUser.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email}
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    value={localUser.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password}
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={localUser.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password}
                    label="Confirm password"
                    name="password2"
                    onChange={handleChange}
                    type="password"
                    value={localUser.password2}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button disabled={!profileChanged} color="primary" variant="contained" onClick={saveChanges}>
            Save changes
          </Button>
        </Box>
      </Card>
    </Container>
  )
}

export default Account
