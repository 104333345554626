import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
const lightMuiTheme = createMuiTheme({
  palette: {
    type: 'light',
    common: {
      black: '#000000',
      white: '#ffffff',
      grey: 'rgba(0, 0, 0, 0.87)',
    },
    primary: {
      light: '#58a5f0',
      main: '#0277bd',
      dark: '#004c8c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#626464',
      main: '#383a3a',
      dark: '#121414',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
      },
    },
  },
  custom: {},
})

const darkMuiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    common: {
      black: '#000000',
      white: '#ffffff',
      grey: 'rgba(0, 0, 0, 0.87)',
    },
    primary: {
      light: '#ff867c',
      main: '#ef5350',
      dark: '#b61827',
      contrastText: '#000',
    },
    secondary: {
      light: '#efefef',
      main: '#bdbdbd',
      dark: '#8d8d8d',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
      },
    },
  },
  custom: {},
})

export const lightTheme = responsiveFontSizes(lightMuiTheme)
export const darkTheme = responsiveFontSizes(darkMuiTheme)
