import { format as formatFns, parseISO } from 'date-fns'

/**
 * formatDate - formats the given date (by default it uses USA formatted string)
 *
 * @param {*} date - Date object or iso date string
 * @param {string} [fmt='MMM dd, yyyy p'] - Format of the output string
 * @returns {string} - returns formatted date or empty string if date is not provided
 */
export const formatDate = (date, fmt = 'MMM dd, yyyy p') => {
  if (!date) return ''
  const parsedTime = Object.prototype.toString.call(date) === '[object Date]' ? date : parseISO(date)
  return formatFns(parsedTime, fmt)
}
