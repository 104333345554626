import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

import { classes as cl } from '../utils/classes'
import { overlayVisible } from '../store/overlay/overlay.selectors'

const useStyles = makeStyles((theme) => ({
  container: {
    // alignSelf: 'center',
    // display: 'flex',
    // flexDirection: 'column',
    // minWidth: 1300,
    margin: '0 auto',
    overflow: 'auto',
    position: 'relative',
    width: '100%',
    // width: '96vw',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // alignItems: 'stretch',
      alignItems: 'flex-start',
    },
  },
  centeredLoader: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  flexGrow: {
    flexGrow: 1,
  },
  titleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '100%',
  },
  subtitle: {
    color: theme.palette.action.active,
    top: '-0.8rem',
    position: 'absolute',
    fontSize: '.7rem',
  },
  title: {
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  body: {
    marginBottom: theme.spacing(),
  },
  actions: {
    marginLeft: 'auto',
  },
  action: {
    marginLeft: theme.spacing(),
  },
  success: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}))

function Page({ title, subtitle, backTo, actions, titleAdornment, children, loadingSelector }) {
  const classes = useStyles()
  const history = useHistory()
  // TODO: make detection when to show loader more precise
  // const isFirstRender = React.useRef(true)

  let isLoading
  if (loadingSelector) {
    const saveOverlayVisible = useSelector(overlayVisible)
    const loadingData = useSelector(loadingSelector)
    isLoading = loadingSelector ? !saveOverlayVisible && loadingData : false
    // isLoading = loadingSelector && isFirstRender.current ? !saveOverlayVisible && loadingData : false
  }

  // React.useEffect(() => {
  //   isFirstRender.current = false
  // }, [])

  return [
    <Fade in={!isLoading} key="component">
      <div className={cl(classes.container, { [classes.flexGrow]: !!children })}>
        <div>
          {title && (
            <Box justifyContent="flex-start" alignItems="center" display="flex" className={classes.titleContainer}>
              <Box flexGrow={1}>
                <Box justifyContent="flex-start" alignItems="center" display="flex" position="relative">
                  {backTo && (
                    <Box alignSelf="center">
                      <Tooltip title={backTo.title || 'Go Back'} aria-label="add">
                        <IconButton edge="start" aria-label="back" onClick={() => history.push(backTo.url)}>
                          <ArrowBackIosIcon style={{ transform: 'translateX(20%)' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Typography variant="h4" className={classes.title}>
                    {title}
                    {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                  </Typography>
                  {titleAdornment}
                  {actions && (
                    <div className={classes.actions}>
                      {actions?.map((action, index) => (
                        <Tooltip
                          title={action.title}
                          aria-label="add"
                          className={cl(classes.action, {
                            [classes[action.color]]: action?.color && action.color !== 'primary',
                          })}
                          key={index}
                        >
                          <Fab color="primary" aria-label="add" size="small" onClick={action.onClick}>
                            {action.icon}
                          </Fab>
                        </Tooltip>
                      ))}
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {children && (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow="1"
              marginBottom="1rem"
              alignItems="flex-stretch"
              minWidth="100%"
            >
              {children}
            </Box>
          )}
        </div>
      </div>
    </Fade>,
    <Fade in={isLoading} key="animation" unmountOnExit>
      <div className={classes.centeredLoader}>
        <CircularProgress />
      </div>
    </Fade>,
  ]
}

export default Page
