import { arrHasArrItems, compareSelections } from './array'
import { CHAMBERS, CHANNELS } from './chartConfig'

export const handleChamberClick = (chamber, masks, testsData, setTestsData) => {
  const startingChannel = (chamber - 1) * 4
  const chamberChannels = CHAMBERS.map((k) => k + startingChannel).filter((c) => !masks.includes(c))
  const update = []

  // Find if all the channels belonging to chamber in all the tests are already selected
  let allChecked = true
  for (const test of testsData) {
    if (!test.isMasked && !arrHasArrItems(chamberChannels, test.channels)) {
      // There are missing channels - just break / exit the loop
      allChecked = false
      break
    }
  }
  // Now toggle all chamber channels from all the tests
  for (const test of testsData) {
    // And make sure cycles are unique
    const selectedChannels = test.isMasked
      ? test.channels
      : [
          ...new Set(
            allChecked
              ? test.channels.filter((c) => !chamberChannels.includes(c))
              : test.channels.concat(chamberChannels),
          ),
        ]
    update.push({ ...test, channels: selectedChannels })
  }
  setTestsData(update)
}

export const handleChannelClick = (channel, testsData, setTestsData) => {
  const update = []
  let allChecked = true
  for (const test of testsData) {
    if (!test.isMasked && !test.channels.includes(channel)) {
      allChecked = false
      break
    }
  }
  for (const test of testsData) {
    const selectedChannels = test.isMasked // If test is masked, just copy whats selected there
      ? test.channels
      : [...new Set(allChecked ? test.channels.filter((c) => c !== channel) : test.channels.concat(channel))]
    update.push({ ...test, channels: selectedChannels })
  }
  setTestsData(update)
}

export const handleSingleClick = (channel, testData, testsData, setTestsData) => {
  if (testData.channels.includes(channel)) {
    testData.channels = testData.channels.filter((c) => c !== channel)
  } else {
    testData.channels.push(channel)
  }
  // To force the state change we need to return different object to setState
  setTestsData(testsData.map((test) => ({ ...test })))
}

export const handleLabelClick = (testData, masks, testsData, setTestsData) => {
  const maskedChannels = CHANNELS.filter((c) => !masks.includes(c))
  const enabledMaskedChannels = testData.channels.filter((c) => masks.includes(c))
  if (arrHasArrItems(maskedChannels, testData.channels)) {
    // Remove all but channels that are enabled but masked
    testData.channels = [...enabledMaskedChannels]
  } else {
    // Clone the channels array and add any channels that are enabled but masked
    testData.channels = maskedChannels.slice().concat(enabledMaskedChannels)
  }
  // To force the state change we need to return different object to setState
  setTestsData(testsData.map((test) => ({ ...test })))
}

export const handleMaskClick = (mask, masks, setMasks) => {
  const update = masks.includes(mask) ? masks.filter((m) => m !== mask) : masks.concat(mask)
  setMasks(update)
}

export const handleAllMaskClick = (masks, setMasks) => {
  // Just toggle all masks
  setMasks(arrHasArrItems(CHANNELS, masks) ? [] : CHANNELS.slice())
}

export const handleLabelMaskClick = (testData, testsData, setTestsData) => {
  testData.isMasked = !testData.isMasked
  setTestsData(testsData.map((test) => ({ ...test })))
}

export const handleAllTestMasks = (allTestsMasked, testsData, setTestsData) => {
  setTestsData(testsData.map((test) => ({ ...test, isMasked: !allTestsMasked })))
}

export const handleSelectAll = (masks, tests, testsData, setTestsData) => {
  const update = []
  const selectedChannels = testsData.reduce((acc, { UUID, channels, isMasked }) => {
    if (isMasked) return acc
    // Keep the array flat by concatenating UUID and all non-masked channels selected
    const testChannels = channels.filter((c) => !masks.includes(c))
    if (testChannels.length) {
      acc = acc.concat({ UUID, channels: testChannels })
    }
    return acc
  }, [])
  const allChannels = tests.reduce((acc, { UUID, curves }) => {
    const testChannels = []
    // Skip tests that are masked
    const isMasked = testsData.find((t) => t.UUID === UUID)?.isMasked
    if (isMasked) return acc
    // Loop trough all the curves and collect non-masked index (increased by 1, since mask ids starts from 1)
    curves.forEach((c, index) => {
      if (!masks.includes(index + 1)) testChannels.push(index + 1)
    })
    acc = acc.concat({ UUID, channels: testChannels })
    return acc
  }, [])

  // If all non-masked tests are selected - empty the selection (but skip masked channels)
  if (compareSelections(selectedChannels, allChannels)) {
    for (const test of testsData) {
      update.push({ ...test, channels: test.isMasked ? test.channels : test.channels.filter((c) => masks.includes(c)) })
    }
  } else {
    // Otherwise select all non-masked channels in each test
    for (const test of testsData) {
      let selectedChannels
      if (test.isMasked) {
        selectedChannels = test.channels
      } else {
        const testRecord = tests.find((t) => t.UUID === test.UUID)
        // const selectedChannels = testRecord.curves.filter((c, i) => !masks.includes(i + 1)).map((c, i) => i + 1)
        selectedChannels = testRecord.curves.reduce((acc, k, i) => {
          // If the current index is not masked or if its already selected in the test channels, include it
          if (!masks.includes(i + 1) || test.channels.includes(i + 1)) {
            acc.push(i + 1)
          }
          return acc
        }, [])
      }
      update.push({ ...test, channels: selectedChannels })
    }
  }
  setTestsData(update)
}
