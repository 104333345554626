import io from 'socket.io-client'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import feathersAuth from '@feathersjs/authentication-client'
import { authConfig, HOST } from './config'

const api = feathers()

// Auth config must go before socket
api.configure(feathersAuth(authConfig))

// Now initialize socket and configure it
const socket = io(HOST, {
  transports: ['websocket'],
  forceNew: true,
  path: '/api/ws/',
})

api.configure(socketio(socket, { timeout: 50000 }))

socket.on('reconnect', () => {
  console.log('re-authenticating')
  api.reAuthenticate(true)
})

window.logger = (message) => api.service('logger').create({ data: message })

// Expose API for development only
if (process.env.NODE_ENV === 'development') {
  window.api = api
}

export default api
export { socket }
