import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HelpIcon from '@material-ui/icons/Help'
import WalletIcon from '@material-ui/icons/AccountBalanceWallet'

import { getAvatar, getName, isAdmin } from '../store/selectors/auth.selectors'
import { services } from '../store/feathers'

const useStyles = makeStyles((theme) =>
  createStyles({
    listIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(),
    },
  }),
)

const LinkedMenuItem = React.forwardRef(({ children, handleClose = () => {}, to = '', ...props }, ref) => {
  const history = useHistory()
  return (
    <MenuItem
      ref={ref}
      {...props}
      onClick={() => {
        history.push(to)
        handleClose()
      }}
    >
      {children}
    </MenuItem>
  )
})

export default function UserMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const avatarImg = useSelector(getAvatar)
  const name = useSelector(getName)
  const hasAdminRights = useSelector(isAdmin)
  const dispatch = useDispatch()
  const classes = useStyles()

  const logOut = (evt) => {
    setAnchorEl(null)
    dispatch(services.auth.logout())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton edge="start" aria-label="user menu" onClick={handleClick}>
        {avatarImg ? <Avatar alt={name} src={avatarImg} /> : <AccountCircleIcon />}
      </IconButton>
      <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <LinkedMenuItem to="/about" handleClose={handleClose}>
          <ListItemIcon className={classes.listIcon}>
            <HelpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="About" />
        </LinkedMenuItem>
        <Divider />
        <LinkedMenuItem to="/portfolios" handleClose={handleClose}>
          <ListItemIcon className={classes.listIcon}>
            <WalletIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Portfolios" />
        </LinkedMenuItem>
        <LinkedMenuItem to="/account" handleClose={handleClose}>
          <ListItemIcon className={classes.listIcon}>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </LinkedMenuItem>
        {hasAdminRights && [
          <Divider key="1" />,
          <LinkedMenuItem to="/users" handleClose={handleClose} key="2">
            <ListItemIcon className={classes.listIcon}>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Manage Users" />
          </LinkedMenuItem>,
          <Divider key="3" />,
        ]}
        <MenuItem onClick={logOut}>
          <ListItemIcon className={classes.listIcon}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </div>
  )
}
