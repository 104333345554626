import { isObject } from '../object'

/**
 * arrHasArrItems - Function that checks if arr1 has all the arr2 items
 * (this doesn't check the items order)
 *
 * @param {array} arr1 - Array which items will be checked
 * @param {array} arr2 - Array that needs to contain all the arr1 items
 * @return {boolean} - Returns true or false
 */
export const arrHasArrItems = (arr1, arr2) => arr1.every((i) => arr2.includes(i))

/**
 * arraysEqual - Function that checks if arrays have same length and contains the same items
 * (this doesn't check the items order)
 *
 * @param {array} arr1 - First array to check
 * @param {array} arr2 - Second array to check
 * @return {boolean} - Returns true or false
 */
export const arraysEqual = (arr1, arr2) =>
  Array.isArray(arr1) && Array.isArray(arr2) && arr1.length === arr2.length && arrHasArrItems(arr1, arr2)

/**
 * arraysIdentical - Function that checks if two arrays are identical - they
 * must have same values at the same positions
 *
 * @param {array} arr1 - First array to check
 * @param {array} arr2 - Second array to check
 * @return {boolean} - Returns true or false
 */
export const arraysIdentical = (arr1, arr2) => arr1.every((a1, index) => arr2[index] === a1)

/**
 * compareSelections - Checks if two selections composed by array of objects
 * containing UUID and channels array are identical
 *
 * @param {array} arr1 - First array to check
 * @param {array} arr2 - Second array to check
 * @return {boolean} - Returns true or false
 */
export const compareSelections = (arr1, arr2) =>
  arr1.length === arr2.length &&
  arr1.every((obj1) => {
    if (!isObject(obj1)) return false
    const obj2 = arr2.find((obj2) => obj2.UUID === obj1.UUID)
    return arraysIdentical(obj1.channels, obj2.channels)
  })
