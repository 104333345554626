import { createAsyncThunk } from '@reduxjs/toolkit'
import { socket } from '../../utils/api'
import { actions } from './socket.slice'

export const checkSocket = createAsyncThunk('socket/isConnected', async (args, thunkArgs) => {
  const { dispatch, getState } = thunkArgs
  socket.on('connect_error', () => {
    // Send message only if socket was connected previously
    if (getState().socket.connected) {
      dispatch(actions.disconnected())
    }
  })

  socket.on('connect', () => {
    // Send message only if socket was disconnected previously
    if (!getState().socket.connected) {
      dispatch(actions.connected())
    }
  })

  if (socket.connected) {
    return Promise.resolve(true)
  } else {
    return Promise.resolve(false)
  }
})
