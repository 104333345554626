import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  spacer: {
    flexGrow: 1,
  },
}))

const Spacer = () => {
  const classes = useStyles()
  return <div className={classes.spacer} />
}

export default Spacer
