import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import DialogTitle from './DialogTitle'
import OkButton from './Buttons/Ok'

const useStyles = makeStyles((theme) => ({
  textarea: {
    minHeight: theme.spacing(2) * 10,
  },
}))

const AddEditAnnotation = ({ open, onClose, onSave, annotation }) => {
  const [content, setContent] = React.useState(annotation)
  const classes = useStyles()

  React.useEffect(() => {
    setContent(annotation)
  }, [annotation])

  const handleChange = (event) => {
    setContent(event.target.value)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle onClose={onClose}>{content ? 'Edit annotation' : 'Add annotation'}</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          id="text"
          label="Text"
          type="text"
          multiline
          fullWidth
          rowsMax={4}
          defaultValue={content}
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            classes: { input: classes.textarea },
            onFocus: (evt) => {
              // Fix ugly bug - textarea in dialog has caret position issues on focus
              const element = evt.target
              // Set caret to the end of the text
              element.setSelectionRange(element.value.length, element.value.length)
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <OkButton variant="outlined" onClick={() => onSave(content)}>
          Save
        </OkButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditAnnotation
