import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  params: {},
  isOpen: false,
}

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    // synchronous actions
    showModal: (state, action) => {
      Object.assign(state, { params: action.payload }, { isOpen: true })
    },
    hideModal: (state, action) => ({ ...initialState }),
  },
})

export default slice

export const { name, actions, reducer } = slice
