export const slugify = (text, sep = '_') =>
  text
    .toString()
    .normalize('NFD')
    .replace(/đ/g, 'dj')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, sep)
    .replace(/[^\w-]+/g, '')
    .replace(/_+/g, sep)
