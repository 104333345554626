import React from 'react'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Checkbox from '@material-ui/core/Checkbox'

function Row(props) {
  const { record, classes, columns, labelId, onSelect, ExpandedView, isItemSelected, idField, selectionDisabled } =
    props
  const [open, setOpen] = React.useState([])
  const toggle = () => {
    if (open.includes(record[idField])) {
      // Create a copy of the state array
      const update = open.slice()
      // remove the current row from opened array
      update.splice(open.indexOf(record[idField]), 1)
      // update state
      setOpen(update)
    } else {
      // create copy of open array and append current record ID
      setOpen([...open, record[idField]])
    }
  }
  const isOpen = open.includes(record[idField])

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} selected={isItemSelected}>
        {onSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              disabled={selectionDisabled ? selectionDisabled(record) : false}
              inputProps={{ 'aria-labelledby': labelId }}
              onChange={onSelect}
            />
          </TableCell>
        )}
        {ExpandedView && (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={toggle}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col, index) => (
          <TableCell key={index} align={col.align || 'left'} className={col.nowrap && classes.nowrap}>
            {col.render ? col.render(record) : record[col.field]}
          </TableCell>
        ))}
      </TableRow>
      {ExpandedView && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <ExpandedView data={record} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// }

export default Row
