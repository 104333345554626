/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for "wait" milliseconds.
 * @example
 * window.addEventListener('resize', debounce(function () {
 *   // do something
 * }, 500))
 *
 * @param  {function} callback - function to be debounced
 * @param  {number}   [wait=100] - wait time until it will be executed
 * @return {function} - debounced function
 */
export const debounce = (callback, wait = 100) => {
  let timeout
  return (...args) => {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => callback.apply(context, args), wait)
  }
}
