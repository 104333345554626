// import { checkSocket } from '../socket/socket.asyncActions'
import { services } from '../feathers'
import { checkSocket } from '../socket/socket.asyncActions'

export const initialize = () => {
  return (dispatch) => {
    dispatch(checkSocket())
    dispatch(services.auth.authenticate())
    dispatch(services.project.find())
  }
}
