import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

import { getPortfolio } from '../store/selectors/portfolios.selectors'
import { services } from '../store/feathers'

const moveTest = (tests, testUUID, delta) => {
  const clone = [...tests]
  const from = tests.findIndex((item) => item.UUID === testUUID)
  const to = from + delta
  if (to < 0 || to === tests.length) {
    // Just silently return, preventing the move
    return
  }
  Array.prototype.splice.call(clone, to, 0, Array.prototype.splice.call(clone, from, 1)[0])
  return clone
}

const MoveActions = (props) => {
  const { record } = props
  const dispatch = useDispatch()
  const currentPortfolio = useSelector(getPortfolio)
  const moveAndSave = (direction) => {
    if (currentPortfolio) {
      const update = moveTest(currentPortfolio.tests, record.UUID, direction)
      if (update) {
        dispatch(services.portfolios.patch(currentPortfolio._id, { tests: update }))
      }
    }
  }

  return (
    <>
      <ArrowUpward style={{ cursor: 'pointer' }} onClick={() => moveAndSave(-1)}></ArrowUpward>
      <ArrowDownward style={{ cursor: 'pointer' }} onClick={() => moveAndSave(1)}></ArrowDownward>
    </>
  )
}

export default MoveActions
