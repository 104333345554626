import { reduxFeathers, reduxFeathersAuth } from '../utils/redux/feathers'
import api from '../utils/api'
import { showSnack } from './snack'

const endPoints = ['users', 'tests', 'project', 'portfolios']

const [services, slices] = reduxFeathers({
  api,
  services: endPoints,
  methods: { project: ['find'] },
  handleErrors: showSnack,
  reducers: {
    tests: {
      // Custom tests action that will force table re-render without fetching the data
      // used when selection changes - user adds a test to portfolio and therefore it should be disabled for selection
      forceReload(state) {
        state.fetchedAt = new Date().getTime()
      },
    },
    portfolios: {
      showAll(state, action) {
        if (state.query) {
          Object.assign(state.query, { showAll: !!action.payload })
        } else {
          state.query = { showAll: !!action.payload }
        }
      },
    },
  },
  idFields: { tests: 'UUID' },
  defaultSorts: { portfolios: { updatedAt: -1 }, tests: { date: -1 } },
})

// Create auth service and its reducer
// const [auth, slice] = reduxFeathersAuth(api, {}, showSnack)
const [auth, slice] = reduxFeathersAuth(api, {})

// Combine them
Object.assign(services, { auth })
slices.push(slice)

// Extract custom actions
const actions = slices.reduce((acc, { name, reducer, actions: serviceActions }) => {
  acc[name] = serviceActions
  return acc
}, {})

export { services, slices, actions }
