import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getValByPath, isEmpty, objectHas } from '../../utils/object'
import { services } from '../../store/feathers'
import EnhancedTable from './Table'
import { fastDeepEqual } from '../../utils/object/fastDeepEqual'

const sortAlgorithms = {
  number: (a, b) => {
    // Number sort
    return a - b
  },
  date: (a, b) => {
    // Date sort
    return new Date(a) - new Date(b)
  },
  string: (a, b) => {
    // Alphanumeric (natural) sort
    return String(a).localeCompare(b, 'en', { numeric: true })
  },
}

function flatSearch(orderBy, order, sortFnc, calcFnc) {
  let flattenSearch
  // We must handle nested object paths with dot notation (ex: "property.record.width")
  const isPath = orderBy.includes('.')
  if (isPath) {
    // In case `field` is a path, wrap the sorting function in a wrapper that reads nested property before calling the sort
    flattenSearch = (a, b) => sortFnc(getValByPath(a, orderBy), getValByPath(b, orderBy))
  } else {
    if (calcFnc) {
      // In case column has a calcFnc - field is virtual so we need to call that function to get the actual value
      flattenSearch = (a, b) => sortFnc(calcFnc(a), calcFnc(b))
    } else {
      flattenSearch = (a, b) => sortFnc(a[orderBy], b[orderBy])
    }
  }
  // When order is inverse switch the arguments
  return order == 1 ? flattenSearch : (a, b) => flattenSearch(b, a)
}

export function getComparator(columns, data, order, orderBy, customSort) {
  // Find the column that is being used to sort the data
  const found = columns.find((c) => c.field === orderBy)
  if (found) {
    const { customSort, type, calcFnc } = found
    if (customSort) {
      return order == 1 ? found.customSort : (a, b) => found.customSort(b, a)
    } else if (type) {
      const sortFnc = sortAlgorithms[found.type] || sortAlgorithms.string
      return flatSearch(orderBy, order, sortFnc, calcFnc)
    }
  }

  // Try to determine type of the field by looking into the data
  if (data.length) {
    // Possible algorithms : number / date / string - fallback to string
    const sortType = getValByPath(data[0], orderBy)
    const sortFnc = sortAlgorithms[sortType] || sortAlgorithms.string
    return flatSearch(orderBy, order, sortFnc)
  }

  // Fallback to a dummy function that doesn't do anything (without sorting)
  return (a, b) => 0
}

// eslint-disable-next-line no-unused-vars
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export class StaticDataTable extends Component {
  state = {
    data: [],
    $limit: this.props.limit || 20,
    $skip: 0,
    $sort: this.props.sort || { id: 1 },
    $filter: {},
  }

  onChange = (query, forceChange = false) => {
    const update = {}
    Object.entries(query).forEach(([property, value]) => {
      switch (property) {
        case 'search': {
          if (value) {
            // When search is passed reset the pagination to first page ($skip = 0)
            Object.assign(update, { $search: value, $skip: 0 })
            update.$search = value
          } else {
            update.$search = undefined
          }
          break
        }
        case 'filter': {
          if (value && !isEmpty(value) && Object.keys(value).length) {
            // Make a copy of current filters object
            update.$filter = { ...this.state.$filter }
            Object.entries(value).forEach(([key, val]) => {
              if (objectHas(update.$filter, key) && val === undefined) {
                delete update.$filter[key]
              } else {
                update.$filter[key] = val
              }
            })
          } else {
            update.$filter = undefined
          }
          // Move to page 1 when filter changes
          update.$skip = 0
          // Force the call even if the filter is empty as it's used to clear previous filters
          forceChange = true
          break
        }
        case 'skip': {
          update.$skip = value
          break
        }
        case 'limit': {
          update.$limit = value
          break
        }
        case 'sort': {
          update.$sort = value
          if (this.props.onSortChange) {
            this.props.onSortChange(value)
          }
          break
        }
        // no default
      }
    })
    if (this.props.total !== this.state.total) {
      update.$skip = 0
      update.total = this.props.total
    }
    if (forceChange || Object.keys(update).length) {
      this.setState(update)
    }
  }

  getData = () => {
    const { $sort, $search, $filter, $skip, $limit } = this.state
    const [[orderBy, order]] = Object.entries($sort)
    const customFilters = this.props.customFilters
    // let data = stableSort(this.props.data, getComparator(this.props.columns, this.props.data, order, orderBy))
    // Clone the data array and sort it by selected column
    let data = Array.from(this.props.data).sort(getComparator(this.props.columns, this.props.data, order, orderBy))

    if (($filter && Object.keys($filter).length) || $search) {
      const columnFields = this.props.columns.map((c) => c.field).filter(Boolean)
      data = data.filter((row) => {
        let passes = true
        if ($filter) {
          const filters = Object.entries($filter)
          for (const [property, filter] of filters) {
            const currVal = property.includes('.') ? getValByPath(row, property) : row[property]
            if (customFilters && customFilters[property]) {
              const customPasses = customFilters[property](filter, row)
              if (!customPasses) {
                passes = false
                break
              }
            } else if (filter?.type === 'date') {
              const currDate = new Date(currVal)
              // filter out any date thats not in the range (before the start
              // and after the end date if given respectively)
              if ((filter.startDate && filter.startDate > currDate) || (filter.endDate && filter.endDate < currDate)) {
                passes = false
                break
              }
            } else if (['$null', '$notNull', '$true', '$false'].includes(filter)) {
              // Cast filter values to true/false and check if boolean pass
              const filterVal = filter === '$true' || filter === '$notNull'
              if (!!currVal !== filterVal) {
                passes = false
                break
              }
            } else if (typeof currVal === 'string' && !currVal.includes(filter)) {
              // If any filter isn't met return false and stop looping
              passes = false
              break
            } else if (typeof currVal === 'number' && !isNaN(filter) && !currVal.toString().includes(filter)) {
              passes = false
              break
            }
          }
        }
        if (passes && $search) {
          passes = columnFields.some((field) => {
            let ret = false
            if (customFilters && customFilters[field]) {
              ret = customFilters[field]($search, row)
            } else {
              const currVal = field.includes('.') ? getValByPath(row, field) : row[field]
              if (typeof currVal === 'string' && currVal.toLowerCase().includes($search.toLowerCase())) {
                // If any filter isn't met return false and stop looping
                ret = true
              } else if (typeof currVal === 'number' && currVal === $search) {
                ret = true
              }
            }
            return ret
          })
          // passes = findInObjectValues(row, $search, true)
          // passes = Object.values(row).join(' ').toLowerCase().includes($search.toLowerCase())
        }
        return passes
      })
    }
    const total = data.length
    data = data.slice($skip, $skip + $limit)
    return [data, total]
  }

  constructor(props) {
    super(props)
    if (props.find) {
      props.find()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchedAt !== this.props.fetchedAt) {
      this.onChange(this.state, true)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Re-render only when fetchedAt changes (new data has arrived) or if the state has changes
    return nextProps.fetchedAt !== this.props.fetchedAt || !fastDeepEqual(this.state, nextState)
  }

  render() {
    const { data, skip, limit, sort, filter, total, ...props } = this.props
    const [currentData, currentTotal] = this.getData()

    return (
      <EnhancedTable
        {...props}
        data={currentData}
        filter={this.state.$filter}
        limit={this.state.$limit}
        onChange={this.onChange}
        skip={this.state.$skip}
        sort={this.state.$sort}
        total={currentTotal}
      />
    )
  }
}

const mapStateToProps = function (state, { service, selector, limit = 20, defaultSort: sort = { id: -1 } }) {
  const serviceState = state[service]
  // Make sure all the properties have default values if not present
  const data = selector ? selector(serviceState) : [...(Array.isArray(serviceState.find) ? serviceState.find : [])]
  const serviceData = Object.assign({}, { data, limit, skip: 0, total: data.length, sort })

  return {
    fetchedAt: serviceState.fetchedAt,
    ...serviceData,
  }
}

const mapDispatchToProps = (dispatch, { service, selector, method = 'find', recordId }) => {
  const serviceQuery = method === 'find' ? { query: { $limit: -1 } } : recordId
  return {
    find: (query) => dispatch(services[service][method](serviceQuery)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticDataTable)
