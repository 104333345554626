import React from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Header from './components/Header'
import About from './pages/About'
import Users from './pages/Users'
import Account from './pages/Account'
import CurveMetrics from './pages/CurveMetrics'
import Login from './pages/Login'
import AddTests from './pages/AddTests'
import Portfolios from './pages/Portfolios'
import Portfolio from './pages/Portfolio'
import { authFinalized, isAuthenticated, isAdmin } from './store/selectors/auth.selectors'
import LoadingOverlay from './components/Overlay'
import ConditionalRoute from './components/ConditionalRoute'
import Modal from './components/Modal/Modal'
import Snack from './components/SnackBar/Snack'

export default function Routes() {
  const isAuthorized = useSelector(isAuthenticated)
  const authCheckDone = useSelector(authFinalized)
  const userIsAdmin = useSelector(isAdmin)
  // Wait for the auth check to finish invalidation before rendering the routes
  // as we need to know if a user is logged in before rendering protected pages
  return authCheckDone ? (
    <Router>
      <Modal />
      <Snack />
      <LoadingOverlay />
      <Header />
      <Switch>
        <ConditionalRoute exact path="/about" component={About} condition={isAuthorized} />
        <ConditionalRoute exact path="/login" component={Login} condition={!isAuthorized} redirect="/portfolios" />
        <ConditionalRoute exact path="/account" component={Account} condition={isAuthorized} />
        <ConditionalRoute exact path="/account/:userId" component={Account} condition={userIsAdmin} />
        <ConditionalRoute exact path="/portfolios" component={Portfolios} condition={isAuthorized} />
        <ConditionalRoute exact path="/portfolios/:portfolioId" component={Portfolio} condition={isAuthorized} />
        <ConditionalRoute
          exact
          path="/portfolios/:portfolioId/add-tests"
          component={AddTests}
          condition={isAuthorized}
        />
        <ConditionalRoute
          exact
          path="/portfolios/:portfolioId/show-curve"
          component={CurveMetrics}
          condition={isAuthorized}
        />
        <ConditionalRoute exact path="/users" component={Users} condition={userIsAdmin} />
        <Redirect to="/portfolios" />
      </Switch>
    </Router>
  ) : null
}
