import React from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import OkButton from './Buttons/Ok'
import ColorPicker from './ColorPicker'
import { arraysEqual } from '../utils/array'
import {
  colorOptions,
  colorLabels,
  defaultChannelColors,
  defaultChamberColors,
  defaultColor,
} from '../utils/chartConfig'

const useStyles = makeStyles((theme) => ({
  colorList: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',

    '& > div': {
      borderBottom: '1px solid #ccc',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),

      '&:last-child': {
        borderBottom: 'none',
      },

      '& > div:first-child': {
        flexGrow: 1,
        whiteSpace: 'nowrap',
      },
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
  },
  btn: {
    whiteSpace: 'nowrap',
  },
  colorBtn: {
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  formControl: {
    float: 'right',
    marginLeft: theme.spacing(2),
  },
  label: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    marginRight: 'auto',
  },
  // Needed to prevent dialog overflow from cutting out colorPicker popup on the bottom edge
  root: { overflow: 'visible' },
}))

const sortLikePortfolio = (tests) => {
  return function (a, b) {
    return tests.findIndex((t) => t.UUID === a[0]) - tests.findIndex((t) => t.UUID === b[0])
  }
}

const getOptions = (portfolio, type) => {
  const property = colorOptions[type]
  const menuItems =
    type === 4
      ? // When RunID is selected we need to use tests to sort/map the records, otherwise the sort order would be wrong
        portfolio.tests.map(({ UUID }) => {
          const record = portfolio.testRecords.find((r) => r.UUID === UUID)
          return record[property]
        })
      : type === 5
      ? portfolio.tests.map(({ UUID }) => {
          const record = portfolio.testRecords.find((r) => r.UUID === UUID)
          return record?.meta?.sampleInfo1Value
        })
      : portfolio.testRecords.map((rec) => rec[property])

  // Return non empty unique items
  return Array.from(new Set(menuItems)).filter(Boolean)
}

const getColorConfig = (portfolio, colorMapType) => {
  const type = colorOptions[colorMapType]
  let data

  if (colorMapType && portfolio?.colorConfig?.[type]?.length) {
    // We have saved color configuration, load that
    data = [...portfolio?.colorConfig?.[type]]

    // Cleanup any previously removed items from saved data (UUIDS / instrument IDS / sampleInfo1Value)
    if (type === 'UUID' || type === 'instrumentId' || type === 'sampleInfo1Value') {
      const availableItems = portfolio.testRecords.map((t) => (type === 'sampleInfo1Value' ? t?.meta[type] : t[type]))
      data = data.filter(([item]) => availableItems.includes(item))
    }
  } else {
    // Create new configuration for the selected color mapping
    switch (type) {
      case 'Default':
        data = []
        break
      case 'Channel':
        data = defaultChannelColors
        break
      case 'Chamber':
        data = defaultChamberColors
        break
      default:
        // config does not exist in saved settings, extract default items
        data = getOptions(portfolio, colorMapType).map((prop) => [prop, { ...defaultColor }])
    }
  }

  if (type === 'UUID') {
    // Always sort UUIDS, so we get exact order (sort might change after colorConfig has been saved)
    if (data.length && portfolio?.tests) {
      data.sort(sortLikePortfolio(portfolio?.tests))
    }
  }

  return data
}

const ColorMap = (props) => {
  const { config, type, labels, classes, save } = props

  if (!config?.length)
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        {`Either no configuration was found for the selected color map`}
        <br />
        {`or the current portfolio doesn't contain any tests yet.`}
      </Box>
    )

  return (
    <div className={classes.colorList}>
      {config.map(([key, value], index) => (
        <div key={key}>
          <div>{type === 4 ? labels[key] || key : key || `Key ${index}`}</div>
          <ColorPicker
            key={`cp-${index}`}
            color={value}
            onChange={({ a, ...col }) => {
              // swallow alpha as we aren't using it
              const update = [...config]
              update[index] = [update[index][0], col]
              save(update)
            }}
          />
        </div>
      ))}
    </div>
  )
}

const ColorsDialog = (props) => {
  const classes = useStyles()
  const { message, onClose, open, portfolio, ...other } = props
  const portfolioColorMap = colorOptions.indexOf(portfolio?.colorConfig?.type || 'Default')
  const [colorMapType, setColorMapType] = React.useState(portfolioColorMap)
  const [colorConfig, setColorConfig] = React.useState(getColorConfig(portfolio, colorMapType))

  const LabelOverrides = {}

  // Create label alternatives that will be used to override RunID (UUID to Sample1Info)
  if (colorMapType === 4) {
    const items = getOptions(portfolio, colorMapType)
    portfolio.testRecords.forEach(({ index, UUID, meta: { sampleInfo1Value } }) => {
      if (items.includes(UUID)) {
        LabelOverrides[UUID] = `${index}. ${sampleInfo1Value}`
      }
    })
  } else if (colorMapType === 5) {
    const items = getOptions(portfolio, colorMapType)
    if (!(colorConfig.length && arraysEqual(colorConfig, items))) {
      console.log('changes detected!')
    }
  }

  React.useEffect(() => {
    setColorConfig(getColorConfig(portfolio, colorMapType))
  }, [colorMapType])

  if (!open) return null

  const switchMapping = (value) => {
    setColorMapType(value)
  }

  return (
    <Dialog
      classes={{ paper: classes.dialog, paperScrollPaper: classes.root }}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="color-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="color-dialog-title" onClose={() => onClose(false)}>
        Configure Chart Colors
        <FormControl className={classes.formControl}>
          <div>
            <Typography variant="body1" component="span" className={classes.label}>
              Color Mode:
            </Typography>
            <Select
              value={colorMapType}
              onChange={(evt) => switchMapping(evt.target.value)}
              inputProps={{
                name: 'color-type',
                id: 'color-type',
              }}
            >
              {colorOptions.map((option, index) => (
                <MenuItem key={index} value={index}>
                  {colorLabels[option] || option}
                </MenuItem>
              ))}
            </Select>
          </div>
        </FormControl>
      </DialogTitle>
      <DialogContent dividers>
        {colorMapType === 0 ? (
          'Default chart colors are FAM=Blue, VIC=Green, CFR=Yellow, QSR=Red'
        ) : (
          <ColorMap
            config={colorConfig}
            labels={LabelOverrides}
            type={colorMapType}
            save={setColorConfig}
            classes={classes}
          />
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.spacer} />
        <Button className={classes.btn} onClick={() => onClose(false)} variant="outlined" color="primary" autoFocus>
          Cancel
        </Button>
        <OkButton
          className={classes.btn}
          onClick={() => onClose({ [colorOptions[colorMapType]]: colorConfig, type: colorOptions[colorMapType] })}
          variant="outlined"
        >
          Save to portfolio
        </OkButton>
      </DialogActions>
    </Dialog>
  )
}

export default ColorsDialog
