import { isObjectOrArray } from './helpers'
/**
 * mergeObjWithObj - Function that merges source object into the target object
 *
 * @param {object} target - target object
 * @param {object} source - source object
 * @returns {object} - returns target object
 */
const mergeObjWithObj = (target, source) => {
  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]
    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue)
    } else if (isObjectOrArray(targetValue) && isObjectOrArray(sourceValue)) {
      target[key] = mergeObjWithObj(Object.assign({}, targetValue), sourceValue)
    } else {
      target[key] = sourceValue
    }
  })
  return target
}

/**
 * deepMerge - function that performs deep objects merge, it accepts arbitrary number of objects
 * @example
 * deepMerge({a: 1}, {b: 2}, {c: 3})
 * // outputs - { a:1, b:2, c:3 }
 *
 * @param {...object} objects
 * @returns
 */
export const deepMerge = (...objects) => {
  if (objects.length < 2) {
    throw new Error('deepMerge: this function expects at least 2 objects to be provided')
  }
  if (objects.some((object) => !isObjectOrArray(object))) {
    throw new Error('deepMerge: all values should be of type "object"')
  }
  const target = objects.shift()
  let source
  // eslint-disable-next-line no-cond-assign
  while ((source = objects.shift())) {
    mergeObjWithObj(target, source)
  }
  return target
}
