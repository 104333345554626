import * as selectors from './snack.selectors'
import slice from './snack.slice'

export const {
  name,
  actions: { showSnack, hideSnack },
  reducer,
} = slice

export const { selectSlice: selectSnack, isOpen } = selectors
