import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import Popover from '@material-ui/core/Popover'

import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import isWithinInterval from 'date-fns/isWithinInterval'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import subDays from 'date-fns/subDays'

import { classes as cl } from '../../utils/classes'
import { formatDate } from '../../utils/formatDate'

const useStyles = makeStyles((theme) => ({
  dates: {
    marginRight: theme.spacing(),
    minWidth: 185,
    flexGrow: 1,
  },
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  popoverRoot: {
    display: 'flex',
    backgroundColor: theme.palette.action.active,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  wrap: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pointer: {
    cursor: 'pointer',
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: '.69rem',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    minHeight: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid rgba(0,0,0,0.42)',

    '& svg': {
      alignSelf: 'center',
    },
  },
}))

const renderDay = (date, startDateProp, endDateProp, selectedDate, dayInCurrentMonth, classes) => {
  let dayIsBetween
  const startDate = startDateProp instanceof Date ? startDateProp : new Date(startDateProp)
  const endDate = endDateProp ? endDateProp instanceof Date ? endDateProp : new Date(endDateProp) : null
  const isFirstDay = isSameDay(date, startDate)
  const isLastDay = isSameDay(date, endDate)

  if (isFirstDay || isLastDay) {
    dayIsBetween = true
  } else if (startDate && endDate) {
    dayIsBetween = isWithinInterval(date, { start: startDate, end: endDate })
  } else if (startDate) {
    dayIsBetween = isAfter(date, startDate)
  } else if (endDate) {
    dayIsBetween = isBefore(date, endDate)
  }

  const wrapperClassName = cl({
    [classes.highlight]: dayIsBetween,
    [classes.firstHighlight]: isFirstDay,
    [classes.endHighlight]: isLastDay,
  })

  const dayClassName = cl(classes.day, {
    [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
    [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
  })

  return (
    <div className={wrapperClassName}>
      <IconButton className={dayClassName}>
        <span> {format(date, 'd')} </span>
      </IconButton>
    </div>
  )
}

const Filter = (props) => {
  const { classes, value, onChange } = props

  const handleStartDateChange = (date) => {
    onChange({ ...value, startDate: date ? startOfDay(date) : date })
  }
  const handleEndDateChange = (date) => {
    onChange({ ...value, endDate: date ? endOfDay(date) : date })
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={cl(classes.dates)}
        label="Start date"
        variant="inline"
        value={value.startDate}
        placeholder="2020-12-31"
        onChange={handleStartDateChange}
        maxDate={value.endDate}
        format="yyyy-MM-dd"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => handleStartDateChange(null)}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        renderDay={(date, selectedDate, dayInCurrentMonth) =>
          renderDay(date, value.startDate, value.endDate, selectedDate, dayInCurrentMonth, classes)
        }
      />

      <KeyboardDatePicker
        className={cl(classes.dates)}
        label="End date"
        variant="inline"
        placeholder="2020-12-31"
        value={value.endDate}
        minDate={value.startDate}
        onChange={handleEndDateChange}
        format="yyyy-MM-dd"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => handleEndDateChange(null)}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        renderDay={(date, selectedDate, dayInCurrentMonth) =>
          renderDay(date, value.startDate, value.endDate, selectedDate, dayInCurrentMonth, classes)
        }
      />
    </MuiPickersUtilsProvider>
  )
}

const DateFilter = (props) => {
  const { field, value, onChange, ...rest } = props
  const now = new Date()
  const previousWeek = startOfDay(subDays(now, 7))
  const endOfToday = endOfDay(new Date())
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [localValue, setLocalValue] = React.useState({ startDate: previousWeek, endDate: endOfToday })

  React.useEffect(() => {
    if (value && (value.startDate !== localValue.startDate || value.endDate !== localValue.endDate)) {
      setLocalValue(value)
    }
  }, [value])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.parentNode.parentNode)
  }

  const handleClose = () => {
    setAnchorEl(null)
    const update = { ...localValue }
    Object.defineProperty(update, 'type', {
      enumerable: false,
      writable: true,
      value: 'date',
    })
    onChange(update)
  }

  return (
    <>
      <div className={classes.row} onClick={handleClick}>
        <div className={classes.preview}>
          {value ? (
            <>
              <div>{formatDate(localValue.startDate, 'MMM dd, yyyy')}</div>
              <div>{formatDate(localValue.endDate, 'MMM dd, yyyy')}</div>
            </>
          ) : (
            <div />
          )}
        </div>
        {value && (value.startDate || value.endDate) && (
          <ClearIcon
            fontSize="small"
            className={`${classes.clear} ${classes.pointer}`}
            onClick={(evt) => {
              evt.stopPropagation()
              onChange(undefined)
              // onChange({ target: { value: '' } })
            }}
          />
        )}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        classes={{
          root: classes.popoverRoot,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <div className={classes.wrap}>
          <Filter classes={classes} {...rest} value={localValue} onChange={setLocalValue} />
        </div>
      </Popover>
    </>
  )
}

export default DateFilter
