import { makeStyles } from '@material-ui/core/styles'

export const useSelectionGridStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& $headerSpacer': {
      flexBasis: 31,
      flexGrow: 0,
    },
  },
  headerSpacer: {},
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      display: 'flex',
      flexBasis: 28,
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  labelCell: {
    alignItems: '',
    flexBasis: '161px !important',
    flex: '0 !important',
  },
  legendScrollOverlay: {
    direction: 'rtl',
    overflowY: 'auto',
    '& > div': {
      direction: 'ltr',
    },
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  maskRow: {
    '&:before, &:after': {
      background: theme.palette.primary.dark,
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: 1,
    },
    '&:before': {
      left: 0,
    },
    '&:after': {
      right: 0,
    },
    background: '#dbe6ef',
    position: 'relative',
    color: theme.palette.primary.dark,
    '& td, & > div': {
      borderTop: `1px solid ${theme.palette.primary.dark}`,
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  hideLine: {
    display: 'block',
    width: '100%',
    height: 1,
    position: 'absolute',
    top: -1,
    background: '#dbe6ef',
    left: 0,
  },
  nameLabel: {
    overflow: 'hidden',
    direction: 'rtl',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    padding: `0 ${theme.spacing()}px`,
    whiteSpace: 'nowrap',
    width: 132,
  },
  noBb: {
    '&:before': {
      background: '#dbe6ef',
      bottom: -1,
      left: 0,
      content: '""',
      display: 'block',
      position: 'absolute',
      height: 1,
      width: '100%',
    },
    position: 'relative',
  },
  bottomMask: {
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
  },
  textBlue: {
    color: theme.palette.primary.dark,
  },
  selectAll: {
    transform: 'translateY(50%)',
    textAlign: 'center',
  },
  borderRight: {
    '&:before': {
      background: theme.palette.primary.dark,
      right: 0,
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: 1,
    },
    position: 'relative',
  },
  pointer: {
    cursor: 'pointer',
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey['500']}`,
    '&:after, &:before': {
      bottom: '100%',
      left: '32px',
      border: 'solid transparent',
      content: '""',
      height: '0',
      width: '0',
      position: 'absolute',
      pointerEvents: 'none',
      marginTop: '3px',
    },
    '&:after': {
      borderColor: 'rgba(136, 183, 213, 0)',
      borderBottomColor: theme.palette.background.paper,
      borderWidth: '6px',
      marginLeft: '-6px',
    },
    '&:before': {
      borderColor: 'transparent',
      borderBottomColor: theme.palette.grey['500'],
      borderWidth: '7px',
      marginLeft: '-7px',
    },
  },
  cell: {
    '&:before': {
      // marginLeft: 0,
      left: '50%',
    },
    '&:after': {
      // marginLeft: 0,
      left: '50%',
    },
  },
}))
