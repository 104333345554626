/**
 * isObjectOrArray - Function that checks if given variable is an object or array
 *
 * @param {*} obj - Variable to check
 * @returns {boolean} - returns true or false
 */
export const isObjectOrArray = (obj) => obj && typeof obj === 'object'

/**
 * objectHas - function that checks if given object has a specific property
 *
 * @param {object} object - object to check
 * @param {*} property - property to check
 * @returns {boolean} - returns true or false
 */
export const objectHas = (object, property) => Object.prototype.hasOwnProperty.call(object, property)

/**
 * isEmpty - function that checks if an object is empty (doesn't have any property)
 *
 * @param {*} object
 * @returns {boolean} - returns true or false
 */
export const isEmpty = (object) => Object.keys(object).length === 0

/**
 * isObject - Function that checks if given variable is an object abd makes sure its not an array
 *
 * @param {*} obj - Variable to check
 * @returns {boolean} - returns true or false
 */
export const isObject = (object) => {
  return object != null && typeof object === 'object' && Array.isArray(object) === false
}

/**
 * Creates an object composed of the filtered object properties.
 * @param obj The source object
 * @param paths The property paths to omit
 */
export const omit = (object, ...keys) =>
  Object.fromEntries(Object.entries(object).filter(([key]) => !keys.includes(key)))

/**
 * Creates an object composed of the picked object properties.
 *
 * @param obj The source object
 * @param paths The property paths to pick
 */
export const pick = (object, ...keys) =>
  Object.fromEntries(Object.entries(object).filter(([key]) => keys.includes(key)))

/**
 * Creates an object composed of the object properties that have values.
 *
 * @param obj The source object
 */
export const trimObject = (object) => Object.fromEntries(Object.entries(object).filter(([key, value]) => !!value))

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * @param Object object The object to search
 * @param String nested A dot notation style parameter reference (ie "urls.small")
 *
 * @return the value of the property in question
 */
export const getValByPath = (object, path) => path.split(/[.[]['"]?/).reduce((acc, part) => acc && acc[part], object)

// /**
//  * A function that searches trough deeply nested object for a match in values
//  * (partial string or equal number)
//  *
//  * @param Object object The object to search
//  * @param String value to search
//  * @param Boolean due a case insensitive search
//  *
//  * @return the value of the property in question
//  */
// export const findInObjectValues = (obj, value, caseInsensitive = false) => {
//   const keys = Object.keys(obj)
//   const type = typeof value
//   return keys.some((key) => {
//     let ret = false
//     if (typeof obj[key] === 'object' && obj[key] !== null) {
//       ret = findInObjectValues(obj[key], value, caseInsensitive)
//     } else if (type === 'string' && typeof obj[key] === 'string') {
//       if (caseInsensitive) {
//         ret = obj[key].toLowerCase().includes(value.toLowerCase())
//       } else {
//         ret = obj[key].includes(value)
//       }
//     } else if (type === 'number') {
//       ret = obj[key] === value
//     }
//     return ret
//   })
// }

/**
 * deepClone - function that makes deep clone of a given object or array
 *
 * @param {*} items - variable to traverse and clone
 * @returns {*} - returns a cloned object or array
 */
export const deepClone = (items) => {
  if (items === null) return null
  if (items === undefined) return undefined
  if (Array.isArray(items) && !items.length) return []
  if (typeof items === 'object' && !Object.keys(items).length) return {}
  if (Array.isArray(items)) {
    return items.map((item) => deepClone(item))
  }
  if (typeof items === 'object') {
    const newObj = {}
    for (const key in items) {
      newObj[key] = deepClone(items[key])
    }
    return { ...newObj }
  }
  return items
}

/**
 * cloneWithFallback - function that makes deep clone of a given object or array
 * and accepts additionally a fallback that can be used if passed value is falsy
 *
 * @param {*} items - object or array to clone
 * @param {*} fallback - value to use if items are false / null or undefined
 * @returns {*} - returns a cloned object or array
 */
export const cloneWithFallback = (items, fallback) =>
  items === null || items === undefined ? fallback : deepClone(items)
