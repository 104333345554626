import * as selectors from './socket.selectors'
import * as asyncActions from './socket.asyncActions'
import slice from './socket.slice'

export const { name, reducer } = slice

export const { checkSocket } = asyncActions

// we prefix all selectors with the the "select" prefix
export const { isConnected } = selectors
