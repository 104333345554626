import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  containedSuccess: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  outlinedSuccess: {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.light}`,
    '&:hover': {
      border: `1px solid ${theme.palette.success.main}`,
    },
    // We need to pass `classes={{disabled: classes.disabled}}` to the Button component for these styles to work.
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
  },
  // This is required for the '&$disabled' selector to work
  disabled: {},
}))

const OkButton = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Button
      className={props.variant === 'outlined' ? classes.outlinedSuccess : classes.containedSuccess}
      classes={props.variant === 'outlined' && { disabled: classes.disabled }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default OkButton
