export const HOST = process.env.API_HOSTNAME

export const authConfig = {
  storage: window.localStorage,
  // The key in localStorage used to store the authentication token
  storageKey: 'auth',
  // Options included in calls to Feathers client.authenticate
  // authenticate: {
  //   strategy: 'local',
  //   // The authentication strategy Feathers should use
  // },
  // The key in localStorage used to store permissions from decoded JWT
  permissionsKey: 'permissions',
  // The key in the decoded JWT containing the user's role
  permissionsField: 'roles',
  // The key used to provide the password to Feathers client.authenticate
  passwordField: 'password',
  // The key used to provide the username to Feathers client.authenticate
  usernameField: 'username',
  // The key used to provide the username to Feathers client.authenticate
  userKey: 'current',
  // Redirect to this path if an AUTH_CHECK fails.
  redirectTo: '/login',
  // Logout when response status code is 403
  logoutOnForbidden: false,
}
